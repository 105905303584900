import React from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import CART_DETAILS_QUERY from '../../../aem-core-components/queries/query_cart_details.graphql';
import { getCartDetails } from '../../../aem-core-components/actions/cart';
import { useFilterState } from '../filterContext';
import { useCartState } from '../../../contexts/cart';
import { usePdpState } from '../../../contexts/pdp/pdpContext';
import { useCheckUser, useCheckAuthorityType } from '../../../hooks/useCheckUser';
import { useUserContext } from '../../../aem-core-components/context/UserContext';
import { useAtp } from './useATP';
import useComputeLocation from '../../../hooks/useComputeLocation';
import useCheckLocationEmpty from '../../../hooks/useCheckLocationEmpty';
import { useAwaitQuery } from '../../../aem-core-components/utils/hooks';
import isObjectEmpty from '../../../aem-core-components/utils/isObjectEmpty';
import {
    geoCodeByLatLong,
    getLocationsDetailsByPlaceId,
    getlatLongByAddress
} from '../../global/modules/location-autocomplete/api/getLocations';
import { formatNearbyPC, formatStoreDataToObject, isCCPage, isTier2Radius } from '../../global/utils/commonUtils';
import { isValidString, logError } from '../../global/utils/logger';
import { getStoreLocations } from '../../location/API/getStoreLocations';
import { getDateDiffInHrs } from '../utils/atputils';
import { getExcludedPCfromList, createFullAddress } from '../utils/capHelper';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { USER_ACCOUNT_TYPE, USER_TYPE } from '../../../constants/userDetailsConstants';
import {
    EDIT_VIEW_DETAILS,
    EDIT_VIEW_OPEN,
    FULFILLMENT_TYPE,
    SET_BSR_LIST,
    SET_IS_CAP_DETAILS_UPDATING,
    SET_PICKUP_STORES,
    TILE_STATES
} from '../constants';
import { usePageType } from '../../../hooks/usePageType';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import useMedia from '../../../hooks/useMedia';
import { MEDIA_TYPE } from '../../../constants/screenConstants';
import {
    RESET_CLICKS,
    SET_RATES_LOADING_FLAG,
    SET_UNAVAILABLE_CART_ITEMS_PER_PC
} from '../../../aem-core-components/actions/constants';
import { AUTHORITY_TYPE } from '../../global/constants';

export const useCapUtils = () => {
    const capIntl = useIntl();
    const cartDetailsQuery = useAwaitQuery(CART_DETAILS_QUERY);
    const authorityType = useCheckAuthorityType();
    const [
        {
            viewCart,
            projectDetails,
            startDate,
            endDate,
            selectedStoreDetails: selectedPickupStoreContext,
            bsrList,
            searchRadiusForLocationCall,
            pickupStores
        },
        dispatch
    ] = useFilterState();
    const [{ userProfile }] = useUserContext();
    const { handleATPCart, handleATP } = useAtp();
    const userType = useCheckUser();
    const [{ cartId, cart, userAccount, unavailableCartItemsPerPc }, cartDispatch] = useCartState();
    const [{ item: pdpItem = {} }, pdpDispatch] = usePdpState();
    const pageType = usePageType();
    const { getBSRList, cityAndZipWithoutStreetValidation, extractAddressComponents, getZipFromLatLong } =
        useComputeLocation();
    const { isRentalDetailsAvailable, fetchLocationCoordinates } = useCheckLocationEmpty();
    const { rollbackToCi } = ENV_CONFIG.INVENTORY_CHECK_CONFIGS || {};
    const { json: capHeaderJson = null } = ENV_CONFIG.CAP_HEADER || {};
    const { tier1SearchRadius, tier2SearchRadius } = searchRadiusForLocationCall || {};
    const isAtpDisabled = rollbackToCi || false;
    const isP2PUser = authorityType === AUTHORITY_TYPE.P2P;
    const isSelectedAccountIsNonCorpAccount = userProfile?.isSelectedAccountIsNonCorpAccount;
    const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
    const mediaType = useMedia();
    const storageCartQuantity = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY) || 0;

    const getJobsiteWithLatLong = async jobsite => {
        try {
            let projectLat = jobsite?.selectedProjectLatititude;
            let projectLong = jobsite?.selectedProjectLongitude;
            if (!projectLat || !projectLong) {
                const companyID = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) || '1';
                const fullAddress = createFullAddress(jobsite, true);
                if (fullAddress) {
                    const response = await getlatLongByAddress(companyID, fullAddress);
                    if (response?.data?.results?.length > 0) {
                        projectLat = response?.data?.results[0]?.geometry?.location?.lat;
                        projectLong = response?.data?.results[0]?.geometry?.location?.lng;
                    }
                }
            }
            return { ...jobsite, selectedProjectLatititude: projectLat, selectedProjectLongitude: projectLong };
        } catch (error) {
            logError(error, false, 'getJobsiteWithLatLong', [jobsite]);
        }
    };

    const distanceText = companyId => {
        let companyID = companyId ? companyId : parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID));
        if (companyID === 2) {
            return capIntl.formatMessage({ id: 'cap:location-km-text' });
        }
        return capIntl.formatMessage({ id: 'cap:location-miles-text' });
    };

    const updateBsrPricingPCs = async payload => {
        const {
            localLat,
            localLong,
            localStartDate = startDate,
            localIsInStorePickup = viewCart?.isInStorePickup,
            companyID,
            isSourceCallRequired = false
        } = payload || {};
        try {
            const isCreditProjectOrCashLocation =
                localLat || projectDetails?.selectedProjectLatititude || viewCart?.lat;
            if (isCCPage()) {
                // to clear always on ccpage for fresh call
                sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.BSR_PC_LIST);
            }
            const dateDiff = getDateDiffInHrs(localStartDate, moment().format('YYYY-MM-DDTHH:mm:ss'));
            if (!localIsInStorePickup && dateDiff <= 24 && isCreditProjectOrCashLocation) {
                let bsrDataForStorage = [];
                if (!isSourceCallRequired) {
                    bsrDataForStorage =
                        JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.BSR_PC_LIST) || '[]') || [];
                }
                if (!bsrDataForStorage || !bsrDataForStorage?.length) {
                    const { data } = await getBSRList({
                        lat: localLat,
                        long: localLong,
                        companyID,
                        updateInContext: !isSourceCallRequired
                    });
                    // Create an array that combines branchCompany and branchNumber
                    if (data?.length > 0) {
                        const combinedArray = data?.map(branch =>
                            formatNearbyPC(branch?.branchNumber, branch?.branchCompany)
                        );
                        if (!isSourceCallRequired) {
                            sessionStorage.setItem(
                                STORAGE_CONFIG.SESSION_STORAGE.BSR_PC_LIST,
                                JSON.stringify(combinedArray)
                            );
                        }
                        return {
                            bsrDataForStorage: combinedArray,
                            bsrDaraForContext: data
                        };
                    } else {
                        if (!isSourceCallRequired) {
                            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.BSR_PC_LIST);
                        }
                        return {
                            bsrDataForStorage: [],
                            bsrDaraForContext: []
                        };
                    }
                }
                return {
                    bsrDataForStorage,
                    bsrDaraForContext: bsrList
                };
            }

            return {
                bsrDataForStorage: [],
                bsrDaraForContext: []
            };
        } catch (error) {
            logError(error, false, 'updateBsrPricingPCs');
        }
    };

    const fetchLocalLocationCoordinates = async (placeId, jobsiteDetails, isJobsiteSelected) => {
        try {
            let lat = '';
            let long = '';
            let addressDetails = {};
            let companyId = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1;
            if (isValidString(placeId) && !isJobsiteSelected) {
                const responseFromPlaceDetails = await getLocationsDetailsByPlaceId(placeId);
                if (responseFromPlaceDetails?.error || isObjectEmpty(responseFromPlaceDetails?.data?.result)) {
                    return;
                }

                let { jobSiteCity, jobSiteState, jobSiteZip, country } = extractAddressComponents(
                    responseFromPlaceDetails?.data?.result,
                    false
                );
                companyId = country;
                lat = responseFromPlaceDetails?.data?.result?.geometry?.location?.lat || '';
                long = responseFromPlaceDetails?.data?.result?.geometry?.location?.lng || '';
                if (!cityAndZipWithoutStreetValidation(responseFromPlaceDetails?.data?.result, companyId)) {
                    return { companyId, lat, long };
                }

                if (!jobSiteZip) {
                    let latlng = `${lat},${long}`;
                    let geocoderesponse = await geoCodeByLatLong(latlng);
                    jobSiteZip = getZipFromLatLong(geocoderesponse?.data?.results[0]);
                }

                addressDetails = {
                    jobSiteAddr2: '',
                    jobSiteCity,
                    jobSiteState,
                    jobSiteZip
                };
            } else {
                lat = jobsiteDetails?.selectedProjectLatititude;
                long = jobsiteDetails?.selectedProjectLongitude;
            }
            return { lat, long, companyId, addressDetails };
        } catch (error) {
            logError(error, false, 'fetchLocalLocationCoordinates');
        }
    };

    const showToggleButton = () => {
        if (
            isRentalDetailsAvailable() &&
            !isValidString(isAtpDisabled) &&
            !isValidString(isTier2Radius()) &&
            !overridePC?.pc
        ) {
            return true;
        } else {
            return false;
        }
    };

    const getCapUserDetails = () => {
        try {
            const capJson = JSON.parse(capHeaderJson) || {};
            if (userType === USER_TYPE.GUEST || userType === USER_TYPE.CASH) {
                return capJson[userType];
            } else if (authorityType === AUTHORITY_TYPE.P2P || authorityType === AUTHORITY_TYPE.PUNCHOUT) {
                return isSelectedAccountIsNonCorpAccount
                    ? capJson[USER_ACCOUNT_TYPE.P2PNONCORP]
                    : capJson[USER_ACCOUNT_TYPE.P2PCORP];
            } else if (overridePC?.pc) {
                return capJson[USER_ACCOUNT_TYPE.OVERRIDEACCOUNT];
            } else {
                return capJson[USER_TYPE.CREDIT];
            }
        } catch (error) {
            logError(error, false, 'getCapUserDetails');
        }
    };

    const getSelectedStoreLabel = selectedPickupStore => {
        if (isObjectEmpty(selectedPickupStore)) {
            return capIntl.formatMessage({ id: 'cap:no-store-nearby' });
        } else {
            return (
                <>
                    {selectedPickupStore?.city} {selectedPickupStore?.state} -{' '}
                    {capIntl.formatMessage({ id: 'cap:store-details-branch' })}
                    {selectedPickupStore?.pc} (
                    {Number(selectedPickupStore?.distance ?? selectedPickupStore?.drivingDistanceFromJobsite).toFixed(
                        2
                    )}{' '}
                    {distanceText(selectedPickupStore?.companyId)})
                </>
            );
        }
    };

    const getLocalEstimatesLocationDetails = ({ isJobsiteSelected, jobsiteWithLatLong, locationDetails }) => {
        try {
            if (isJobsiteSelected) {
                return {
                    lat: jobsiteWithLatLong?.selectedProjectLatititude,
                    long: jobsiteWithLatLong?.selectedProjectLongitude
                };
            } else {
                return {
                    lat: locationDetails?.lat,
                    long: locationDetails?.long
                };
            }
        } catch (error) {
            logError(error, false, 'getLocalEstimatesLocationDetails', [
                isJobsiteSelected,
                jobsiteWithLatLong,
                locationDetails
            ]);
        }
    };

    const getPayloadForCompareEstimates = async ({
        localEstimatesLocationDetails,
        filterStartDate,
        filterEndDate,
        companyID,
        fulfillmentValue,
        isSourceCallRequired
    }) => {
        try {
            let bsrData = {};
            let overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
            if (!overridePC?.pc) {
                if (filterEndDate && filterStartDate) {
                    if (isSourceCallRequired) {
                        bsrData = await updateBsrPricingPCs({
                            localLat: localEstimatesLocationDetails?.lat,
                            localLong: localEstimatesLocationDetails?.long,
                            localStartDate: filterStartDate,
                            localIsInStorePickup: fulfillmentValue === FULFILLMENT_TYPE.PICKUP,
                            companyID,
                            isSourceCallRequired
                        });
                    }
                }
                return bsrData;
            }
        } catch (error) {
            logError(error, false, 'getPayloadForCompareEstimates', [localEstimatesLocationDetails]);
        }
    };

    // calls ATP for each item and send the items with status
    const createUpdatedCartData = (
        filterStartDate,
        fulfillmentValue,
        selectedPickupStore,
        locationDetails,
        bsrData,
        companyID,
        localIsTier2Radius,
        localNearbyPcs,
        estimatesResponse
    ) => {
        try {
            const unavailableItems = [];
            const conditionalAndUnavailableItems = [];
            estimatesResponse?.items?.forEach(data => {
                let isCICheckRequired = filterStartDate ? isValidString(isAtpDisabled) : true;
                let payload = {
                    catsku: data?.product?.sku,
                    inventoryDataObj: data?.product?.ec_pc_inventory,
                    localStartDate: filterStartDate,
                    localIsInStorePickup: fulfillmentValue === FULFILLMENT_TYPE.PICKUP,
                    locationPCData: selectedPickupStore,
                    isLocalLocationEmpty: !locationDetails?.lat,
                    localBsrData: bsrData,
                    localCompanyID: companyID,
                    localIsTier2Radius,
                    localNearbyPcs,
                    pcAvailability: data?.product?.pc_availability
                };
                let tileStatus = isCICheckRequired ? handleATPCart(payload) : handleATP(payload);
                // if (filterStartDate && !isValidString(isAtpDisabled)) {
                //     if (tileStatus === TILE_STATES.UNAVAILABLE) {
                //         unavailableItems.push({ ...data, tileStatus });
                //     }
                // } else if (tileStatus === TILE_STATES.UNAVAILABLE) {
                //     unavailableItems.push({ ...data, tileStatus });
                // }
                if (tileStatus === TILE_STATES.UNAVAILABLE) {
                    unavailableItems.push({ ...data, tileStatus });
                }
                if (tileStatus !== TILE_STATES.AVAILABLE && tileStatus !== TILE_STATES.AVAILABLE_WITH_WARNING) {
                    conditionalAndUnavailableItems.push({ ...data, tileStatus });
                }
            });
            return { unavailableItems, conditionalAndUnavailableItems };
        } catch (error) {
            logError(error, false, 'createUpdatedCartData', [estimatesResponse]);
            return {};
        }
    };

    const getBSRData = async (cartDetailsPayload, locationDetails) => {
        try {
            const NewAddress =
                JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS)) || false;
            const isCreditProjectAddress = userType == USER_TYPE.CREDIT && !NewAddress;
            const {
                isJobsiteSelected = isCreditProjectAddress ? true : false,
                jobsiteDetails = projectDetails,
                companyID = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1,
                filterStartDate = startDate,
                filterEndDate = endDate,
                fulfillmentValue = viewCart?.isInStorePickup ? FULFILLMENT_TYPE.PICKUP : FULFILLMENT_TYPE.DELIVERY,
                isSourceCallRequired = true
            } = cartDetailsPayload;

            let jobsiteWithLatLong = null;
            if (isJobsiteSelected) {
                jobsiteWithLatLong = await getJobsiteWithLatLong(jobsiteDetails);
            }
            const localEstimatesLocationDetails = getLocalEstimatesLocationDetails({
                isJobsiteSelected,
                jobsiteWithLatLong,
                locationDetails
            });
            const bsrData = await getPayloadForCompareEstimates({
                localEstimatesLocationDetails,
                filterStartDate,
                filterEndDate,
                companyID,
                fulfillmentValue,
                isSourceCallRequired
            });
            const { bsrDataForStorage = [], bsrDaraForContext = [] } = bsrData || {};

            return {
                bsrDataForStorage,
                bsrDaraForContext
            };
        } catch (error) {
            logError(error, false, 'getBSRData');
            return {};
        }
    };

    const getCartUpdatedDetails = async cartDetailsPayload => {
        /* To take the values default values from context if details are not passed */
        try {
            let estimatesResponse = cart;
            const { localLat, localLong } = fetchLocationCoordinates();
            const {
                locationDetails = { lat: localLat, long: localLong },
                localNearbyPcs = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ATP_PC_LIST) || '[]'),
                companyID = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1,
                selectedPickupStore = selectedPickupStoreContext,
                filterStartDate = startDate,
                fulfillmentValue = viewCart?.isInStorePickup ? FULFILLMENT_TYPE.PICKUP : FULFILLMENT_TYPE.DELIVERY,
                localIsTier2Radius = isValidString(isTier2Radius())
            } = cartDetailsPayload;
            const { bsrDataForStorage, bsrDaraForContext } = await getBSRData(cartDetailsPayload, locationDetails);
            const { unavailableItems } = createUpdatedCartData(
                filterStartDate,
                fulfillmentValue,
                selectedPickupStore,
                locationDetails,
                bsrDataForStorage,
                companyID,
                localIsTier2Radius,
                localNearbyPcs,
                estimatesResponse
            );
            return {
                cartDetails: unavailableItems,
                bsrDataForStorage,
                bsrDaraForContext
            };
        } catch (error) {
            logError(error, false, 'getCartUpdatedDetails', [cartDetailsPayload]);
            return {};
        }
    };

    /**
     * Fetches the unavailable items in the cart for each pickup store.
     * Will return empty object for tier2 radius and delivery flow.
     *
     * @param {Object} payload - The payload containing cart and rental details.
     * @param {boolean} [isContextUpdateRequired=false] - Flag to determine if the global context needs to be updated.
     * @returns {Object} unavailableCartItemsListPerPc - An object mapping each store's PC to its unavailable items.
     */
    const getCartUnavailableItems = async (payload, isContextUpdateRequired = false) => {
        try {
            const {
                filterStartDate = startDate,
                localIsTier2Radius = isValidString(isTier2Radius()),
                fulfillmentValue = viewCart?.isInStorePickup ? FULFILLMENT_TYPE.PICKUP : FULFILLMENT_TYPE.DELIVERY
            } = payload;
            let unavailableCartItemsListPerPc = {};
            /**
             * For enhancement added conditions to skip calculating unavailable items per pc,
             * as for some case all the pcs will have same unavailable items.
             * For tier2 radius and override pc, all the items are always shown as available.
             * For delivery, the items will have same ATP status for all pcs.
             */

            if (filterStartDate && !localIsTier2Radius && fulfillmentValue !== FULFILLMENT_TYPE.DELIVERY) {
                unavailableCartItemsListPerPc = getUnavailableCartItemsPerPc(payload);
            }
            if (isContextUpdateRequired) {
                cartDispatch({
                    type: SET_UNAVAILABLE_CART_ITEMS_PER_PC,
                    unavailableCartItemsPerPc: unavailableCartItemsListPerPc
                });
            }
            return unavailableCartItemsListPerPc;
        } catch (error) {
            logError(error, false, 'getCartUnavailableItems');
            return {};
        }
    };

    const getUnavailableCartItemsPerPc = payload => {
        try {
            const { localLat, localLong } = fetchLocationCoordinates();
            const {
                locationDetails = { lat: localLat, long: localLong },
                localNearbyPcs = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ATP_PC_LIST) || '[]'),
                companyID = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1,
                pickupStoresData = Object.values(pickupStores || {}),
                filterStartDate = startDate,
                estimatesResponse = cart
            } = payload;
            const unavailableCartItemsObj = {};
            pickupStoresData?.forEach(store => {
                const { conditionalAndUnavailableItems } = createUpdatedCartData(
                    filterStartDate,
                    FULFILLMENT_TYPE.PICKUP,
                    store,
                    locationDetails,
                    [],
                    companyID,
                    false,
                    localNearbyPcs,
                    estimatesResponse
                );
                unavailableCartItemsObj[store.pc] =
                    conditionalAndUnavailableItems?.map(item => ({
                        sku: item?.product?.sku,
                        name: item?.product?.name,
                        tileStatus: item?.tileStatus
                    })) || [];
            });
            return unavailableCartItemsObj;
        } catch (error) {
            logError(error, false, 'getUnavailableCartItemsPerPc');
            return null;
        }
    };

    // fetch cart if cart id is present and for pdp page, return pdp main item if cart is empty
    const getItemsForAvailabilityCheck = async localIsTier2Radius => {
        try {
            if (localIsTier2Radius || overridePC?.pc) {
                return null;
            } else if (pageType?.toLowerCase() === VARIABLE_CONFIG.ECOMMERCE_PAGE.CART) {
                return cart;
            } else if (cartId && isCartQuantityDiffFromStorage()) {
                return getCartDetails({
                    cartDetailsQuery,
                    dispatch: cartDispatch,
                    cartId
                });
            } else if (pageType === VARIABLE_CONFIG.ECOMMERCE_PAGE.DETAIL && pdpItem?.sku) {
                return {
                    items: [
                        {
                            quantity: pdpItem?.qty,
                            product: pdpItem
                        }
                    ],
                    total_quantity: pdpItem?.qty
                };
            }
            return null;
        } catch (error) {
            logError(error, false, 'getItemsForAvailabilityCheck');
            return null;
        }
    };

    const getStoresForSorting = async (localStores, lat, long, distance, companyID) => {
        try {
            if (localStores) {
                return localStores;
            }
            if (lat && long && distance) {
                const storeData = await getStoreLocations(lat, long, distance, companyID);
                return storeData?.data?.data?.pcList || [];
            }
            return [];
        } catch (error) {
            logError(error, false, 'getStoresForSorting');
            return [];
        }
    };

    // Get unavailable items based on various conditions
    const getUnavailableItems = async ({
        estimatesResponse,
        pcList,
        companyID,
        filterStartDate,
        fulfillmentValue,
        localIsTier2Radius,
        localAtpPcs,
        locationDetails,
        isContextUpdateRequired
    }) => {
        try {
            if (estimatesResponse?.items?.length > 0) {
                return getCartUnavailableItems(
                    {
                        locationDetails,
                        localNearbyPcs: localAtpPcs,
                        companyID,
                        pickupStoresData: pcList,
                        filterStartDate,
                        fulfillmentValue,
                        localIsTier2Radius,
                        estimatesResponse
                    },
                    isContextUpdateRequired
                );
            }
            return unavailableCartItemsPerPc;
        } catch (error) {
            logError(error, false, 'getUnavailableItems');
            return {};
        }
    };

    const checkIfTier2 = distance => {
        if (distance) {
            return distance == tier2SearchRadius;
        }
        return isValidString(isValidString(isTier2Radius()));
    };

    const isUnavailableItemsComputationRequired = () => {
        const itemsQuantity = cart?.items?.reduce((totalQuantity, item) => totalQuantity + item?.quantity, 0) || 0;
        return cartId && storageCartQuantity && (storageCartQuantity != itemsQuantity || !unavailableCartItemsPerPc);
    };

    // Fetch cart details and store list, calculate unavailable items then sort store list
    const getSortedStoresByCartItems = async ({
        lat,
        long,
        distance,
        companyID = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1,
        filterStartDate,
        fulfillmentValue,
        localAtpPcs,
        updateUnavailableItemsInContext,
        updateSortedStoresInContext,
        sortIfUnavailableItemsEmpty,
        localStores // if present will not get stores from api
    }) => {
        const localIsTier2Radius = checkIfTier2(distance);
        try {
            const [cartResult, pcList] = await Promise.all([
                getItemsForAvailabilityCheck(localIsTier2Radius),
                getStoresForSorting(localStores, lat, long, distance, companyID)
            ]);
            if (pcList?.length > 0) {
                const unavailableCartItemsList = await getUnavailableItems({
                    estimatesResponse: cartResult || cart,
                    pcList,
                    companyID,
                    filterStartDate,
                    fulfillmentValue,
                    localIsTier2Radius,
                    localAtpPcs,
                    locationDetails: { lat, long },
                    isContextUpdateRequired: updateUnavailableItemsInContext
                });
                const sortedpcList = getSortedStoreList(pcList, unavailableCartItemsList, updateSortedStoresInContext, sortIfUnavailableItemsEmpty);
                const { pricingPcs, pickupStorePcs } = getExcludedPCfromList(sortedpcList);
                return { pricingPcs, pickupStorePcs, distance, unavailableCartItemsList };
            }
            return null;
        } catch (error) {
            logError(error, false, 'getSortedStoresByCartItems');
            return null;
        }
    };

    // Get stores data considering both Tier1 and Tier2 radius
    const getStoresData = async ({
        lat = '',
        long = '',
        companyID,
        tier1Radius = tier1SearchRadius,
        filterStartDate,
        fulfillmentValue,
        localAtpPcs,
        updateUnavailableItemsInContext
    }) => {
        try {
            if (lat && long) {
                // Try fetching data for Tier 1 radius
                const tier1Result = await getSortedStoresByCartItems({
                    lat,
                    long,
                    distance: tier1Radius,
                    companyID,
                    filterStartDate,
                    fulfillmentValue,
                    localAtpPcs,
                    updateUnavailableItemsInContext
                });
    
                if (tier1Result) return tier1Result;
    
                // Otherwise, fetch data for Tier 2 radius
                const tier2Result = await getSortedStoresByCartItems({
                    lat,
                    long,
                    distance: tier2SearchRadius,
                    companyID,
                    filterStartDate,
                    fulfillmentValue,
                    localAtpPcs,
                    updateUnavailableItemsInContext
                });
    
                return (
                    tier2Result || {
                        pricingPcs: [],
                        pickupStorePcs: [],
                        distance: tier1SearchRadius,
                        unavailableCartItemsList: unavailableCartItemsPerPc
                    }
                );
            }
    
            // Return default data when no latitude or longitude is provided
            return {
                pricingPcs: [],
                pickupStorePcs: [],
                distance: tier1SearchRadius,
                unavailableCartItemsList: unavailableCartItemsPerPc
            };
        } catch (error) {
            logError(error, false, 'getStoresData');
            // Return default data when no latitude or longitude is provided
            return {
                pricingPcs: [],
                pickupStorePcs: [],
                distance: tier1SearchRadius,
                unavailableCartItemsList: unavailableCartItemsPerPc
            };
        }
    };    

    const isCapHeroComponent = () => {
        const isHomePage = pageType === VARIABLE_CONFIG.PAGE_TYPE.HOME_PAGE;
        if (isHomePage) {
            return true;
        }
        return false;
    };
    const isLocationPageHeroComponent = () => {
        const isLocationPage = pageType === VARIABLE_CONFIG.PAGE_TYPE.LOCATION_PAGE;
        if (isLocationPage && mediaType == MEDIA_TYPE.DESKTOP) {
            return true;
        }
        return false;
    };

    const startRenting = () => {
        dispatch({ type: EDIT_VIEW_OPEN });
    };

    const onEditViewSourceMatch = () => {
        dispatch({ type: EDIT_VIEW_DETAILS, editViewSource: '' });
    };

    const onRangePickerToggle = isOpen => {
        if (!isOpen) {
            dispatch({ type: EDIT_VIEW_OPEN });
        }
    };

    const handleResetClick = () => {
        cartDispatch({ type: RESET_CLICKS });
        const scrollY = window?.scrollY;
        window.scrollTo(0, scrollY); // to prevent unneccessary scroll after modal close
    };

    const isStoreDetailsUpdated = isStoreLocationFetching => {
        if (isTier2Radius() || !isStoreLocationFetching) {
            return true;
        }
        return false;
    };

    const startUpdateAndRatesLoading = () => {
        try {
            dispatch({ type: SET_IS_CAP_DETAILS_UPDATING, isCapDetailsUpdating: true });
            cartDispatch({
                type: SET_RATES_LOADING_FLAG,
                isRatesLoading: true
            });
            if (pageType === VARIABLE_CONFIG.ECOMMERCE_PAGE.DETAIL) {
                pdpDispatch({
                    type: SET_RATES_LOADING_FLAG,
                    isRatesLoading: true
                });
            }
        } catch (error) {
            cartDispatch({
                type: SET_RATES_LOADING_FLAG,
                isRatesLoading: false
            });
            pdpDispatch({
                type: SET_RATES_LOADING_FLAG,
                isRatesLoading: false
            });
            logError(error, false, 'startUpdateAndRatesLoading');
        }
    };

    const getCapLocationLabel = rentalLocationLabel => {
        if (isP2PUser) {
            if (!userAccount?.accountNumber) {
                return capIntl.formatMessage({ id: 'cap:choose-account-and-jobsite' });
            } else {
                return capIntl.formatMessage({ id: 'cap:choose-a-jobsite' });
            }
        } else {
            return rentalLocationLabel;
        }
    };

    const updateBSRInStorageAndContext = (bsrData = {}) => {
        sessionStorage.setItem(
            STORAGE_CONFIG.SESSION_STORAGE.BSR_PC_LIST,
            JSON.stringify(bsrData?.bsrDataForStorage || [])
        );
        dispatch({ type: SET_BSR_LIST, bsrList: bsrData?.bsrDaraForContext || [] });
    };

    const isCartQuantityDiffFromStorage = () => {
        const itemsQuantity = cart?.items?.reduce((totalQuantity, item) => totalQuantity + item?.quantity, 0) || 0;
        return storageCartQuantity && storageCartQuantity != itemsQuantity;
    };

    /**
     * This function calls stores and cart api. Calculate unavailable items object and save all these in context.
     * @param {*} tier1Radius
     * @returns unavailable cart items object and sorted formatted stores
     */
    const fetchAndUpdatePickupStores = async tier1Radius => {
        try {
            const { localLat, localLong } = fetchLocationCoordinates();
            const { pickupStorePcs, unavailableCartItemsList } = await getStoresData({
                lat: localLat,
                long: localLong,
                tier1Radius,
                updateUnavailableItemsInContext: true
            });
            const formattedStores = formatStoreDataToObject(pickupStorePcs || []) || {};
            dispatch({
                type: SET_PICKUP_STORES,
                pickupStores: formattedStores
            });
            return { formattedStores, unavailableCartItemsList };
        } catch (error) {
            logError(error, false, 'fetchAndUpdatePickupStores');
        }
    };

    /**
     * This function sort stores by highest availability followed by closest distance.
     * @param {*} storeList
     * @param {*} unavailableCartItems
     * @param {*} isContextUpdateRequired
     * @param {*} sortIfUnavailableItemsEmpty
     * @returns
     */
    const getSortedStoreList = (
        storeList,
        unavailableCartItems,
        isContextUpdateRequired = false,
        sortIfUnavailableItemsEmpty = false
    ) => {
        // Function is used to sort store cards by highest availability (All available) first, followed by stores with lower availability and distance.
        // sortIfUnavailableItemsEmpty can be used in scenario where cart becomes empty or fullfillment is changed to delivery.
        try {
            // Check if unavailableCartItems is empty
            if ((!unavailableCartItems || isObjectEmpty(unavailableCartItems)) && !sortIfUnavailableItemsEmpty) {
                return storeList;
            }
            const sortedPcList =
                storeList?.sort((a, b) => {
                    const unavailableStoreA = unavailableCartItems?.[a?.pc] || [];
                    const unavailableStoreB = unavailableCartItems?.[b?.pc] || [];
                    const availabilityDifference = unavailableStoreA?.length - unavailableStoreB?.length;

                    // If product availability is the same, sort by distance
                    if (availabilityDifference === 0) {
                        return a?.distance - b?.distance;
                    }

                    // Otherwise, sort by availability difference
                    return availabilityDifference;
                }) || [];

            if (isContextUpdateRequired) {
                dispatch({
                    type: SET_PICKUP_STORES,
                    pickupStores: formatStoreDataToObject(sortedPcList) || {}
                });
            }
            return sortedPcList;
        } catch (error) {
            logError(error, false, 'getSortedStoreList');
        }
    };

    const updateUnavailableItemsOnDateChange = async filterStartDate => {
        // Function will trigger when date is changed form product tile.
        try {
            if (cart?.items?.length > 0 && !isCartQuantityDiffFromStorage() && pickupStores) {
                const unavailableCartItemsList = await getCartUnavailableItems({ filterStartDate }, true);
                // Code for sorting the stores list based on product availability and distance and update in context
                getSortedStoreList(Object.values(pickupStores || {}), unavailableCartItemsList, true);
            }
        } catch (error) {
            logError(error, false, 'updateUnavailableItemsOnDateChange', [filterStartDate]);
        }
    };

    return {
        getJobsiteWithLatLong,
        distanceText,
        updateBsrPricingPCs,
        fetchLocalLocationCoordinates,
        showToggleButton,
        getCapUserDetails,
        getSelectedStoreLabel,
        getCartUpdatedDetails,
        getStoresData,
        isCapHeroComponent,
        isLocationPageHeroComponent,
        startRenting,
        isStoreDetailsUpdated,
        onEditViewSourceMatch,
        onRangePickerToggle,
        handleResetClick,
        startUpdateAndRatesLoading,
        getCapLocationLabel,
        updateBSRInStorageAndContext,
        fetchAndUpdatePickupStores,
        getSortedStoreList,
        getSortedStoresByCartItems,
        isCartQuantityDiffFromStorage,
        updateUnavailableItemsOnDateChange,
        isUnavailableItemsComputationRequired
    };
};
