import { useAnalyticsContext } from '../../config/GoogleTagManagerEvents';
import useAnalytics from './useAnalytics';
import useAnalyticsHelper from './useAnalyticsHelper';
import { usePageType } from '../usePageType';
import { logError } from '../../components/global/utils/logger';
import { VARIABLE_CONFIG } from '../../constants/analyticsConstants/Variables';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../constants/analyticsConstants/Ecommerce';

const useModalAnalytics = () => {
    const pageType = usePageType();
    const {
        sendEventsForDatePickerFormViewed,
        sendEventsForLocationFormSubmit,
        sendEventsForFormCompleted,
        sendEventsForDatePickerFormReset,
        sendEventsForEcommerceAction,
        sendEventsForUnavailableCta,
        sendEventsForCartModalOpen,
        sendEventsForAVSModalCtaClick,
        sendEventsForCtaClicked
    } = useAnalyticsContext();
    const [{ payloadEcommerceActionAnalytics, payloadEcommerceLocationActionAnalytics }] = useAnalytics();
    const [{ getItemStatusDetails }] = useAnalyticsHelper();

    const sendAnalyticsDatePickerViewEvent = isDateClick => {
        try {
            if (isDateClick === VARIABLE_CONFIG.EVENT.START_DATE_CLICK) {
                sendEventsForDatePickerFormViewed(
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FORM_VIEWED,
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_FORM_NAME,
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_START_DATE_FORM_FIELD,
                    payloadEcommerceActionAnalytics(false)
                );
            } else {
                sendEventsForDatePickerFormViewed(
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FORM_VIEWED,
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_FORM_NAME,
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_END_DATE_FORM_FIELD,
                    payloadEcommerceActionAnalytics(false)
                );
            }
        } catch (error) {
            logError(error, false, 'sendAnalyticsDatePickerViewEvent', [isDateClick]);
        }
    };

    const sendAnalyticsDatePickerModalEvents = (eventType, startDate, endDate) => {
        try {
            switch (eventType) {
                case VARIABLE_CONFIG.EVENT.SUBMIT: {
                    sendEventsForLocationFormSubmit(
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FORM_SUBMITTED,
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_FORM_NAME,
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_PICKER_LINK_NAME,
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_PICKER_LINK_TYPE,
                        payloadEcommerceActionAnalytics(false, startDate, endDate)
                    );
                    break;
                }
                case VARIABLE_CONFIG.EVENT.RESET: {
                    sendEventsForDatePickerFormReset(
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CTA_CLICKED,
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_FORM_NAME,
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_PICKER_FORM_RESET_DATES,
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_PICKER_FORM_RESET_TYPE,
                        pageType
                    );
                    break;
                }
                case VARIABLE_CONFIG.EVENT.COMPLETE: {
                    sendEventsForFormCompleted(
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_PICKER_FORM_COMPLETED,
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_FORM_NAME,
                        payloadEcommerceActionAnalytics(false, startDate, endDate)
                    );
                    break;
                }
                case VARIABLE_CONFIG.EVENT.CLOSED: {
                    sendEventsForDatePickerFormReset(
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CTA_CLICKED,
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_FORM_NAME,
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_PICKER_FORM_CLOSE,
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_PICKER_FORM_RESET_TYPE,
                        pageType
                    );
                    break;
                }
                default:
                    break;
            }
        } catch (error) {
            logError(error, false, 'sendAnalyticsDatePickerModalEvents', [eventType]);
        }
    };

    const sendConfirmationModalAnalytics = (isConfirmed, isCloseClicked, isBackClicked, items) => {
        try {
            sendEventsForUnavailableCta(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CTA_CLICKED,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CONFIRM_CHANGED_DETAILS,
                isCloseClicked
                    ? EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_PICKER_FORM_CLOSE
                    : isBackClicked
                    ? EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_LINK_TYPE
                    : isConfirmed
                    ? EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_YES_CHANGE_DETAILS
                    : EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_NO_DONT_CHANGE_DETAILS,
                isCloseClicked
                    ? EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_ITEMS_UNAVAILABLE_FORM_MODAL_ICON
                    : isBackClicked
                    ? EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_BACK_LINK_TYPE
                    : EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_SUBMITTED_LINK_TYPE,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_ITEMS_UNAVAILABLE_FORM_LINK_LOCATION,
                '',
                getItemStatusDetails(items)
            );
        } catch (error) {
            logError(error, false, 'sendConfirmationModalAnalytics');
        }
    };

    const sendConfirmationModalViewAnalytics = items => {
        try {
            sendEventsForEcommerceAction(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FORM_VIEWED, {
                form_name: EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CONFIRM_CHANGED_DETAILS,
                ...getItemStatusDetails(items)
            });
        } catch (error) {
            logError(error, false, 'sendConfirmationModalViewAnalytics');
        }
    };

    const payloadEcommerceNoStoreNearby = (addressDetails, selectedAddress, nearestPcDistance) => {
        const extractLocation = address => {
            const location = address?.split(',')?.[0]?.trim();
            return location;
        };
        const { jobSiteAddr2, jobSiteCity, jobSiteState, jobSiteZip } = addressDetails || {};
        const selectedAddressObj = {
            line_1: extractLocation(selectedAddress) || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            line_2: jobSiteAddr2 || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            city: jobSiteCity || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            state: jobSiteState || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            zipcode: jobSiteZip || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY
        };
        const userData = payloadEcommerceLocationActionAnalytics(false);
        const { override_pc } = userData?.user || {};
        const user = {
            nearest_pc: VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            override_pc,
            address: selectedAddressObj
        };
        return { user };
    };

    const triggerModalViewedEvent = isUnavailableItems => {
        try {
            sendEventsForCartModalOpen(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FORM_VIEWED,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_MODAL_REVIEW_EQUIPMENT_AVAILABILITY,
                isUnavailableItems
            );
        } catch (error) {
            logError(error, false, 'triggerModalViewedEvent');
        }
    };

    const triggerAvsModalCtaEvent = (storeDetailData, selectedLocationStore) => {
        try {
            sendEventsForAVSModalCtaClick(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CTA_CLICKED,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_MODAL_REVIEW_EQUIPMENT_AVAILABILITY,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_MOCAL_EQUIPMENT_AVAILABILITY_LINK_NAME,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_BUTTON,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_MODAL,
                '',
                { pc_changed: storeDetailData?.pc !== selectedLocationStore?.store?.pc }
            );
        } catch (error) {
            logError(error, false, 'triggerAvsModalCtaEvent');
        }
    };

    const triggerModalCancelEvent = () => {
        try {
            sendEventsForCtaClicked(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CTA_CLICKED,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CLOSE,
                VARIABLE_CONFIG.TYPE.BUTTON,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_MODAL,
                window?.location?.href
            );
        } catch (error) {
            logError(error, false, 'triggerModalCancelEvent');
        }
    };


    return [
        {
            sendAnalyticsDatePickerViewEvent,
            sendAnalyticsDatePickerModalEvents,
            sendConfirmationModalAnalytics,
            sendConfirmationModalViewAnalytics,
            payloadEcommerceNoStoreNearby,
            triggerModalViewedEvent,
            triggerAvsModalCtaEvent,
            triggerModalCancelEvent
        }
    ];
};

export default useModalAnalytics;
