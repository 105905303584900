import React, { memo, useEffect, useState } from 'react';
import { bool, func, object } from 'prop-types';
import { useIntl } from 'react-intl';
import { Button } from '../../../aem-core-components';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Ecommerce';
import { MEDIA_TYPE } from '../../../constants/screenConstants';
import useMedia from '../../../hooks/useMedia';
import AlertCircle from '../../../resources/images/alert-circle-black.svg';
import ArrowRight from '../../../resources/images/arrow-right.svg';
import ChevronLeft from '../../../resources/images/chevron-left-white.svg';
import CloseIcon from '../../../resources/images/close.svg';
import HideIcon from '../../../resources/images/hide.svg';
import PickupHome from '../../../resources/images/pickup-home.svg';
import ShowEyeIcon from '../../../resources/images/showeye.svg';
import { checkoutDatalocator } from '../../checkoutv2/checkoutAndOrderSummary/dataLocators';
import AccountSelector from '../../global/atoms/accountSelector';
import { ChooseAStoreViewWrapper } from '../../global/modules/ChooseAStoreView/ChooseAStoreViewWrapper';
import { EDIT_VIEW_OPEN, STORE_VARIANT } from '../constants';
import { useFilterState } from '../filterContext';
import { useCapAnalytics } from '../hooks/useCapAnalytics';
import { useCapUtils } from '../hooks/useCapUtils';
import './pickupStore.scss';

const PickupStore = ({
    locationDetails,
    selectedPickupStore,
    setSelectedPickupStore,
    storesData,
    isStoresLoading,
    localStartDate,
    pickupstoreContainerRef,
    companyId,
    chooseStoreHandler,
    showStoresCardShimmer,
    localUnavailableCartItemsPerPc
}) => {
    const [, dispatch] = useFilterState();
    const { getSelectedStoreLabel } = useCapUtils();
    const { sendClickAnalyticsEvent, triggerStoreModalAnalytics } = useCapAnalytics();
    const [isStoreModalOpen, setIsStoreModalOpen] = useState(false);
    const [onStoreDetailsClick, setOnStoreDetailsClick] = useState(false);
    const [mapToggle, setMapToggle] = useState(true);
    const [selectedStoreValue, setSelectedStoreValue] = useState('');
    const [storeDetailData, setStoreDetailData] = useState({});
    const mediaType = useMedia();
    const capIntl = useIntl();
    const [nextAvailableStartDate, setNextAvailableStartDate] = useState(localStartDate || new Date());
    const isTabletView = mediaType === MEDIA_TYPE.TABLET;

    useEffect(() => {
        setSelectedStoreValue(selectedPickupStore?.pc || '');
        setStoreDetailData(selectedPickupStore || {});
    }, [selectedPickupStore]);

    useEffect(() => {
        if (localStartDate) {
            setNextAvailableStartDate(localStartDate);
        }
    }, [localStartDate]);

    const handleChooseAStoreClick = () => {
        chooseStoreHandler();
        if (selectedPickupStore?.name) {
            setIsStoreModalOpen(true);
            setOnStoreDetailsClick(false);
        }
    };

    const handleMapToggle = () => {
        setMapToggle(!mapToggle);
    };

    const handleCloseClick = () => {
        dispatch({ type: EDIT_VIEW_OPEN });
        setIsStoreModalOpen(false);
        setStoreDetailData(selectedPickupStore);
        setSelectedStoreValue(selectedPickupStore?.pc || '');
    };

    const onStoreDetailClick = item => {
        setStoreDetailData(item);
        setOnStoreDetailsClick(true);
        setMapToggle(true);
    };

    const sendEvents = () => {
        setOnStoreDetailsClick(false);
        sendClickAnalyticsEvent(storeDetailData, storesData, false);
    };

    const handleChooseStoreFromStoreDetails = store => {
        dispatch({ type: EDIT_VIEW_OPEN });
        let choosenStore = store || storeDetailData;
        saveStoreDetails(choosenStore);
        setSelectedPickupStore(choosenStore);
        setIsStoreModalOpen(false);
        sendClickAnalyticsEvent(storeDetailData, storesData, true);
    };

    const saveStoreDetails = details => {
        details?.operatingHours?.sort((day1, day2) => day1?.dayOfWeek - day2?.dayOfWeek);
        setStoreDetailData(details);
        setSelectedStoreValue(details?.pc);
    };

    const handleChooseThisStoreClick = () => {
        dispatch({ type: EDIT_VIEW_OPEN });
        setIsStoreModalOpen(false);
        setSelectedPickupStore(storeDetailData);
        triggerStoreModalAnalytics(
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_SET_PC_COMPLETE,
            selectedStoreValue,
            storesData?.data?.length,
            localUnavailableCartItemsPerPc
        );
    };

    const mobileCloseButton = () => {
        return (
            <div className="mobile-view-footer mobile-view-footer-white">
                {showStoresCardShimmer ? (
                    <div className="choose-this-store__shimmer shimmer"></div>
                ) : (
                    <Button
                        type="button"
                        className="button button-primary button-block"
                        buttonAriaLabel={capIntl.formatMessage({ id: 'cap:choose-this-store' })}
                        onClick={handleChooseThisStoreClick}
                        data-testid={checkoutDatalocator.mobile_close_button}>
                        {capIntl.formatMessage({ id: 'cap:choose-this-store' })}
                    </Button>
                )}
            </div>
        );
    };

    const pickupStoreDrawerHeading = () => {
        return (
            <>
                <Button
                    data-testid="back-button"
                    className="button"
                    onClick={!onStoreDetailsClick ? handleCloseClick : sendEvents}>
                    <ChevronLeft />
                    Back
                </Button>
                {!onStoreDetailsClick && !isTabletView && (
                    <button
                        data-testid="map-button"
                        className={`${'mapButton'} button`}
                        onClick={handleMapToggle}
                        tabIndex={'0'}>
                        {mapToggle ? (
                            <>
                                <HideIcon className="buttton_icon" aria-hidden={true} tabIndex={'-1'} /> Hide map{' '}
                            </>
                        ) : (
                            <>
                                <ShowEyeIcon className="buttton_icon" aria-hidden={true} tabIndex={'-1'} /> Show map{' '}
                            </>
                        )}
                    </button>
                )}
                <Button data-testid="close-icon" className="button close-icon">
                    <CloseIcon onClick={handleCloseClick} />
                </Button>
            </>
        );
    };
    const pickupStoreDrawerFooter = () => {
        return (
            <>
                {showStoresCardShimmer ? (
                    <div className="choose-this-store__shimmer shimmer"></div>
                ) : (
                    <Button
                        type="button"
                        className="button button-primary button-block"
                        onClick={handleChooseThisStoreClick}
                        buttonAriaLabel={capIntl.formatMessage({ id: 'cap:choose-this-store' })}
                        data-testid={checkoutDatalocator.handleChooseThisStoreClick}>
                        {capIntl.formatMessage({ id: 'cap:choose-this-store' })}
                    </Button>
                )}
            </>
        );
    };

    const renderPickupStore = () => {
        return (
            <div
                ref={pickupstoreContainerRef}
                className={`pickup-store tooltip-pickupstore ${selectedPickupStore?.name ? '' : 'pickupStoreInput__alert'
                    }`}>
                <div className="pickup-store__title">
                    {mediaType === MEDIA_TYPE.DESKTOP && <PickupHome className="home-icon" />}
                    {capIntl.formatMessage({ id: 'cap:pickup-store' })}
                </div>
                {isStoresLoading ? (
                    <div className="pickup-store-shimmer shimmer" />
                ) : (
                    <AccountSelector
                        customButtonView={'capHeader'}
                        isSelectStoreError={false}
                        projectName={''}
                        selectedProjectAddress1={getSelectedStoreLabel(selectedPickupStore)}
                        accountClick={handleChooseAStoreClick}
                        buttonAriaLabel={getSelectedStoreLabel(selectedPickupStore)}
                        testid={'capheader_makeaselection_drpdown_testid'}
                        pickupDefaultIcon={selectedPickupStore?.name ? <ArrowRight /> : <AlertCircle />}
                    />
                )}
            </div>
        );
    };

    return (
        <>
            {renderPickupStore()}
            {isStoreModalOpen && (
                <ChooseAStoreViewWrapper
                    makeSelectedClicked={isStoreModalOpen}
                    mobileCloseButton={mobileCloseButton}
                    onStoreDetailsClick={onStoreDetailsClick}
                    mapToggle={mapToggle}
                    storesData={storesData}
                    selectedStoreValue={selectedStoreValue}
                    setSelectedStoreValue={setSelectedStoreValue}
                    storeDetailData={storeDetailData}
                    mediaType={mediaType}
                    handleChooseThisStoreClick={handleChooseThisStoreClick}
                    onStoreDetailClick={onStoreDetailClick}
                    saveStoreDetails={saveStoreDetails}
                    setOnStoreDetailsClick={setOnStoreDetailsClick}
                    handleChooseStoreFromStoreDetails={handleChooseStoreFromStoreDetails}
                    handleMapToggle={handleMapToggle}
                    handleCloseClick={handleCloseClick}
                    nextAvailableStartDate={nextAvailableStartDate}
                    isBestMatch={false}
                    currentLocation={{ lat: locationDetails?.lat, long: locationDetails?.long }}
                    pickupStoreFooterClass={'pickupStore_footer'}
                    pickupStoreHeaderClass={'pickupStore_header'}
                    mapMobileClass={'map-mobile__content'}
                    variant={STORE_VARIANT.CAP_HEADER}
                    pickupStoreDrawerHeading={pickupStoreDrawerHeading}
                    pickupStoreDrawerFooter={pickupStoreDrawerFooter}
                    showInDrawerForTablet
                    showMonochromaticMap
                    storeDetailsClassName="pickupStore__store-details"
                    storesClassName="pickupStore__stores"
                    companyId={companyId}
                    triggerStoreModalAnalytics={triggerStoreModalAnalytics}
                    showShimmer={showStoresCardShimmer}
                    localUnavailableCartItemsPerPc={localUnavailableCartItemsPerPc}
                    localStartDate={localStartDate}
                />
            )}
        </>
    );
};

PickupStore.propTypes = {
    locationDetails: object,
    selectedPickupStore: object,
    setSelectedPickupStore: func,
    storesData: object,
    isStoresLoading: bool,
    pickupstoreContainerRef: func,
    chooseStoreHandler: func,
    showStoresCardShimmer: bool
};

PickupStore.defaultProps = {
    locationDetails: {},
    selectedPickupStore: {},
    setSelectedPickupStore: () => { },
    storesData: {},
    isStoresLoading: false,
    pickupstoreContainerRef: () => { },
    chooseStoreHandler: () => { },
    showStoresCardShimmer: false
};

export default memo(PickupStore);
