export const YOUR_DETAILS = 'YOUR_DETAILS';
export const HTGO = 'HTGO';
export const ACCESSORIES_AND_ADDONS = 'ACCESSORIES_AND_ADDONS';
export const OPTIONAL_PLANS = 'OPTIONAL_PLANS';
export const PAYMENT = 'PAYMENT';
export const PROJECT_DETAILS = 'PROJECT_DETAILS';
export const TRANSMIT_OR_SAVE = 'TRANSMIT_OR_SAVE';
export const TRANSMIT_NOW = 'TRANSMIT_NOW';
export const SAVE_FOR_LATER = 'SAVE_FOR_LATER';
export const FULFILLMENT_TYPE_DELIVERY = 'Delivery';
export const FULFILLMENT_TYPE_PICKUP = 'Pickup';
export const CLOSED = 'Closed';
export const TRANSMIT_STATUS_FALSE = 'F';
export const VIEW_QUOTE = 'View Quote';
export const CREATE_QUOTE = 'Create Quote';
export const DUKE_CORP_LINK = parseInt(
    document.querySelector('meta[data-endpoint-key="DukeC"]')?.dataset?.endpointValue
);
export const INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER';
export const ADDERESS_NOT_VALIDATED = 'ADDERESS_NOT_VALIDATED';
export const PROJECTSITE_ALREADY_EXISTS = 'PROJECTSITE_ALREADY_EXISTS';

export const SYSTEMIC_ERROR_CODES = ['0', 'A', 'B', 'G', 'M', 'X'];
export const SYSTEMIC_AXIOS_ERROR_CODES = ['ECONNABORTED', 'ERR_NETWORK'];

export const FORM_STEP_CASH = {
    HTGO: 1,
    OPTIONAL_PLANS: 2,
    PAYMENT: 3,
    SUBMIT_RESERVATION: 4
};
export const FORM_STEP_CASH_ADDONS = {
    HTGO: 1,
    ACCESSORIES_AND_ADDONS: 2,
    OPTIONAL_PLANS: 3,
    PAYMENT: 4,
    SUBMIT_RESERVATION: 5
};
export const FORM_STEP_CREDIT_JOBSITE = {
    HTGO: 1,
    OPTIONAL_PLANS: 2,
    PROJECT_DETAILS: 3,
    SUBMIT_RESERVATION: 4
};
export const FORM_STEP_CREDIT_ADDRESS = {
    CREATE_JOBSITE: 1,
    HTGO: 2,
    OPTIONAL_PLANS: 3,
    SUBMIT_RESERVATION: 4
};

export const FORM_STEP_CREDIT_ADDONS = {
    HTGO: 1,
    ACCESSORIES_AND_ADDONS: 2,
    OPTIONAL_PLANS: 3,
    PROJECT_DETAILS: 4,
    SUBMIT_RESERVATION: 5
};
export const FORM_STEP_CREDIT_ADDONS_ADDRESS = {
    CREATE_JOBSITE: 1,
    HTGO: 2,
    ACCESSORIES_AND_ADDONS: 3,
    OPTIONAL_PLANS: 4,
    SUBMIT_RESERVATION: 5
};
export const FORM_STEP_CUSTOM_CREDIT = {
    HTGO: 1,
    OPTIONAL_PLANS: 2,
    PROJECT_DETAILS: 3,
    CUSTOM_ACCOUNT_INFO: 4,
    SUBMIT_RESERVATION: 5
};

export const FORM_STEP_CUSTOM_CREDIT_ADDRESS = {
    CREATE_JOBSITE: 1,
    HTGO: 2,
    OPTIONAL_PLANS: 3,
    CUSTOM_ACCOUNT_INFO: 4,
    SUBMIT_RESERVATION: 5
};
export const FORM_STEP_CUSTOM_CREDIT_ADDONS = {
    HTGO: 1,
    ACCESSORIES_AND_ADDONS: 2,
    OPTIONAL_PLANS: 3,
    PROJECT_DETAILS: 4,
    CUSTOM_ACCOUNT_INFO: 5,
    SUBMIT_RESERVATION: 6
};

export const FORM_STEP_CUSTOM_CREDIT_ADDONS_ADDRESS = {
    CREATE_JOBSITE: 1,
    HTGO: 2,
    ACCESSORIES_AND_ADDONS: 3,
    OPTIONAL_PLANS: 4,
    CUSTOM_ACCOUNT_INFO: 5,
    SUBMIT_RESERVATION: 6
};
export const FORM_STEP_GUEST = {
    YOUR_DETAILS: 1,
    HTGO: 2,
    OPTIONAL_PLANS: 3,
    PAYMENT: 4,
    SUBMIT_RESERVATION: 5
};
export const FORM_STEP_GUEST_ADDONS = {
    YOUR_DETAILS: 1,
    HTGO: 2,
    ACCESSORIES_AND_ADDONS: 3,
    OPTIONAL_PLANS: 4,
    PAYMENT: 5,
    SUBMIT_RESERVATION: 6
};
export const FORM_STEP_CREDIT_WO_OPTIONAL = {
    HTGO: 1,
    PROJECT_DETAILS: 2,
    SUBMIT_RESERVATION: 3
};
export const FORM_STEP_CREDIT_WO_OPTIONAL_ADDRESS = {
    CREATE_JOBSITE: 1,
    HTGO: 2,
    SUBMIT_RESERVATION: 3
};
export const FORM_STEP_CREDIT_ADDONS_WO_OPTIONAL = {
    HTGO: 1,
    ACCESSORIES_AND_ADDONS: 2,
    PROJECT_DETAILS: 3,
    SUBMIT_RESERVATION: 4
};

export const FORM_STEP_CREDIT_ADDONS_WO_OPTIONAL_ADDRESS = {
    CREATE_JOBSITE: 1,
    HTGO: 2,
    ACCESSORIES_AND_ADDONS: 3,
    SUBMIT_RESERVATION: 4
};
export const FORM_STEP_CUSTOM_CREDIT_WO_OPTIONAL = {
    HTGO: 1,
    PROJECT_DETAILS: 2,
    CUSTOM_ACCOUNT_INFO: 3,
    SUBMIT_RESERVATION: 4
};

export const FORM_STEP_CUSTOM_CREDIT_WO_OPTIONAL_ADDRESS = {
    CREATE_JOBSITE: 1,
    HTGO: 2,
    CUSTOM_ACCOUNT_INFO: 3,
    SUBMIT_RESERVATION: 4
};

export const FORM_STEP_CUSTOM_CREDIT_ADDONS_WO_OPTIONAL = {
    HTGO: 1,
    ACCESSORIES_AND_ADDONS: 2,
    PROJECT_DETAILS: 3,
    CUSTOM_ACCOUNT_INFO: 4,
    SUBMIT_RESERVATION: 5
};

export const FORM_STEP_CUSTOM_CREDIT_ADDONS_WO_OPTIONAL_ADDRESS = {
    CREATE_JOBSITE: 1,
    HTGO: 2,
    ACCESSORIES_AND_ADDONS: 3,
    CUSTOM_ACCOUNT_INFO: 4,
    SUBMIT_RESERVATION: 5
};
export const FORM_STEP_CASH_ADDONS_WO_OPTIONAL = {
    HTGO: 1,
    ACCESSORIES_AND_ADDONS: 2,
    PAYMENT: 3,
    SUBMIT_RESERVATION: 4
};
export const FORM_STEP_CASH_WO_OPTIONAL = {
    HTGO: 1,
    PAYMENT: 2,
    SUBMIT_RESERVATION: 3
};
export const FORM_STEP_GUEST_ADDONS_WO_OPTIONAL = {
    YOUR_DETAILS: 1,
    HTGO: 2,
    ACCESSORIES_AND_ADDONS: 3,
    PAYMENT: 4,
    SUBMIT_RESERVATION: 5
};
export const FORM_STEP_GUEST_WO_OPTIONAL = {
    YOUR_DETAILS: 1,
    HTGO: 2,
    PAYMENT: 3,
    SUBMIT_RESERVATION: 4
};
// CREATE QUOTE STEPS START...
export const FORM_STEP_CREATE_QUOTE = {
    HTGO: 1,
    OPTIONAL_PLANS: 2,
    TRANSMIT_OR_SAVE: 3
};
export const FORM_STEP_CREATE_QUOTE_ADDONS = {
    HTGO: 1,
    ACCESSORIES_AND_ADDONS: 2,
    OPTIONAL_PLANS: 3,
    TRANSMIT_OR_SAVE: 4
};
export const FORM_STEP_CREATE_QUOTE_WO_OPTIONAL = {
    HTGO: 1,
    TRANSMIT_OR_SAVE: 2
};
export const FORM_STEP_CREATE_QUOTE_ADDONS_WO_OPTIONAL = {
    HTGO: 1,
    ACCESSORIES_AND_ADDONS: 2,
    TRANSMIT_OR_SAVE: 3
};

// CREATE QUOTE FOR ADDRESS

export const FORM_STEP_CREDIT_CREATE_QUOTE = {
    CREATE_JOBSITE: 1,
    HTGO: 2,
    OPTIONAL_PLANS: 3,
    TRANSMIT_OR_SAVE: 4
};

export const FORM_STEP_CREDIT_CREATE_QUOTE_ADDONS = {
    CREATE_JOBSITE: 1,
    HTGO: 2,
    ACCESSORIES_AND_ADDONS: 3,
    OPTIONAL_PLANS: 4,
    TRANSMIT_OR_SAVE: 5
};

export const FORM_STEP_CREDIT_CREATE_QUOTE_WO_OPTIONAL = {
    CREATE_JOBSITE: 1,
    HTGO: 2,
    TRANSMIT_OR_SAVE: 3
};

export const FORM_STEP_CREDIT_CREATE_QUOTE_ADDONS_WO_OPTIONAL = {
    CREATE_JOBSITE: 1,
    HTGO: 2,
    ACCESSORIES_AND_ADDONS: 3,
    TRANSMIT_OR_SAVE: 4
};

// CREATE QUOTE STEPS END.

export const SBRWEB = 'SBRWEB';
export const SUCCESS_CODE_200 = 200;
export const BACK_TO_CART_CTA_ID = 'back-to-cart-header-btn';
export const SUNBELT_HEADER_LOGO_CTA_ID = 'sunbelt-header-logo';
export const CHECKOUT_ADDRESS2_ID = 'address2';
export const CHECKOUT_START_DATE_ID = 'start-date';
export const CHECKOUT_END_DATE_ID = 'end-date';
export const CHECKOUT_ADDRESS_ID = 'address';
export const CHECKOUT_CITY_ID = 'city';
export const CHECKOUT_STATE_ID = 'state';
export const PROJECT_ACCESS_NOTES_ID = 'accessNotes';
export const JOBSITE_NAME_ID = 'jobsiteName';
export const JOBSITE_PRIMARY_CONTACT_ID = 'primaryContact';
export const JOBSITE_PHONE_NUMBER_ID = 'phoneNumber';
export const JOBSITE_ACCESS_NOTES_ID = 'accessNotes';
export const JOBSITE_PO_NUMBER_ID = 'poNumber';
export const CHECKOUT_AVAILABLE_TIME_ID = 'available-time';
export const CHECKOUT_PICKUPTIME_ID = 'pickup-time';
export const CHECKOUT_RETURNTIME_ID = 'return-time';
export const CHECKOUT_OPTIONAL_FUEL_CHECKBOX_ID = 'checkout_optional_fuel_checkbox';
export const CHECKOUT_OPTIONAL_RPP_CHECKBOX_ID = 'checkout_optional_rpp_checkbox';
export const CHECKOUT_ALERT_TYPE_DATE = 'DATE';
export const CHECKOUT_ALERT_TYPE_TIME = 'TIME';
export const YOUR_DETAILS_FIELDS = {
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName'
};
export const RENTAL_PROTECTION_PLAN = 'RENTAL PROTECTION PLAN';
export const FORCE_ITEM = {
    YES: 'Y',
    NO: 'N'
};
export const SCROLL_MARGIN_TOP_VALUE = 48;
