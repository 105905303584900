import React from 'react';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router } from 'react-router-dom';
import {
    App as CommerceApp, ConfigContextProvider
} from '../../aem-core-components';
import partialConfig from '../App/config';
import Global from '../App/Global';
import loadLocaleData, { locale, messages } from '../App/i18n';
import { initSentry } from '../global/utils/logger';

const App = props => {
    const {
        storeView,
        graphqlEndpoint,
        graphqlMethod = 'POST',
        headers = '{}'
    } = JSON.parse(
        document.querySelector('meta[name="store-config"]')?.content ||
        document.querySelector('div[name="store-config"]')?.getAttribute('content') ||
        '{}'
    );
    const config = {
        ...partialConfig,
        storeView,
        graphqlEndpoint,
        // Can be GET or POST. When selecting GET, this applies to cache-able GraphQL query requests only. Mutations
        // will always be executed as POST requests.
        graphqlMethod,
        headers
    };
    return (
        <IntlProvider locale={locale} messages={messages}>
            <ConfigContextProvider config={config}>
                <CommerceApp>
                    <Global isCCHeader />
                </CommerceApp>
            </ConfigContextProvider>
        </IntlProvider>
    );
};

const renderDOM = async () => {
    const { locale, messages } = await loadLocaleData();
    const root = document.createElement('div');
    document.body.appendChild(root);
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (!isDevelopment) {
        await initSentry();
    }
    const rootContainer = createRoot(root);
    rootContainer.render(
        <Router>
            <App locale={locale} messages={messages} />
        </Router>
    );
};

document?.addEventListener('readystatechange', () => {
    if (document?.readyState === 'complete') {
        renderDOM();
    }
});

export default App;
