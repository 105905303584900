import React from 'react';
import { gql } from '@apollo/client';
import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import * as Sentry from '@sentry/react';
import GoogleTagManagerEvents from '../../../config/GoogleTagManagerEvents';

import Timeout from '../../../components/global/atoms/timeout/Timeout';
import {CartProvider, CartInitializer } from '../../../contexts/cart';
import { CheckoutProvider } from '../Checkout';
import UserContextProvider from '../../context/UserContext';
import { useConfigContext } from '../../context/ConfigContext';
import { graphqlAuthLink } from '../../utils/authUtils';
import compressQueryFetch from '../../utils/compressQueryFetch';
import { PdpProvider } from '../../../contexts/pdp/pdpContext';
import SentryFallback from '../../../components/App/SentryFallback';
import { LocationProvider } from '../../../contexts/location/locationContext';
import { CANCELLED_REQUEST_ERRORS } from '../../../constants/errorMappingCodes';
import parseError from '../../utils/parseError';
import { HelmetProvider } from 'react-helmet-async';
import { DstProvider } from '../../../contexts/dst/dstContext';
import { FavouritesProvider } from '../../../components/favorites/context';
import { FilterProvider } from '../../../components/cap';
import { ModalProvider } from '../../context/ModalContext';
import { ToastProvider } from '../../../components/global/modules/toastMessage/toastContext';
import { QuotesProvider } from '../../../components/quotes/context';
import { PurchaseOrderListProvider } from '../../../components/purchaseOrder/context';


const sentryFallback = ({ error, componentStack, resetError }) => (
    <SentryFallback error={error} componentStack={componentStack} resetError={resetError} />
);
const typeDefs = gql`
    type Reservation {
        storeId: String!
        customerType: String
        customer: String
        driverLicenseState: String
        driverLicenseNumber: String
        jobNumber: String
        isDelivery: Boolean
        deliveryInstructions: String
        startDateTime: String
        endDateTime: String
        earliestDateTime: String
        rentalProtectionPlanFlag: String
        fuelFlag: String
        deliveryCharge: Float
        orderByEmail: String
        areaCode: String
        phoneNumber: String
        sessionID: String
        tokenID: String
        tokenPvID: String
        tokenExpirationDate: String
        allocateAsset: Boolean
        channel: String
        address1: String
        city: String
        state: String
        zip: String
        distance: Int
        pickUpCharge: Float
        products: [Product!]
        orderedBy: String
        sourceSystem: String
    }

    type Product {
        equipment: String
        productId: String
        lineSequence: String
        quantity: Int
        freeFlag: String
        toolFlex: String
        hourRate: Float
        minimumRate: Float
        dayRate: Float
        weeklyRate: Float
        monthlyRate: Float
        dayOver: String
        weekOver: String
        monthOver: String
        isSerialized: Boolean
    }
`;

const App = props => {
    const { graphqlEndpoint, storeView, graphqlMethod = 'POST', headers = {} } = useConfigContext();

    const clientHeaders = { ...headers };

    if (storeView) {
        clientHeaders['Store'] = storeView;
    }
    const logoutLink = onError(({ networkError }) => {
        if (networkError?.statusCode === 401) {
        }
    });
    const retryLink = new RetryLink({
        attempts: (count, operation, error) => {
            if (CANCELLED_REQUEST_ERRORS.includes(parseError(error))) {
                return true;
            } else {
                return false;
            }
        },
        delay: (count, operation, error) => {
            return count * 1000;
        }
    });

    const clientConfig = {
        link: from([
            retryLink,
            graphqlAuthLink,
            logoutLink,
            new HttpLink({
                uri: graphqlEndpoint,
                headers: clientHeaders,
                useGETForQueries: graphqlMethod === 'GET',
                fetch: compressQueryFetch
            })
        ]),
        typeDefs,
        cache: new InMemoryCache()
    };

    const client = new ApolloClient(clientConfig);

    return (
        <Sentry.ErrorBoundary fallback={sentryFallback}>
            <ApolloProvider client={client}>
                <GoogleTagManagerEvents>
                    <HelmetProvider>
                        <UserContextProvider>
                            <FilterProvider>
                                <CartProvider>
                                    <CartInitializer>
                                        <CheckoutProvider>
                                            <PdpProvider>
                                                <LocationProvider>
                                                    <DstProvider>
                                                        <FavouritesProvider>
                                                            <QuotesProvider>
                                                                <PurchaseOrderListProvider>
                                                                    <ToastProvider>
                                                                        <Timeout>
                                                                            <ModalProvider>{props.children}</ModalProvider>
                                                                        </Timeout>
                                                                    </ToastProvider>
                                                                </PurchaseOrderListProvider>
                                                            </QuotesProvider>
                                                        </FavouritesProvider>
                                                    </DstProvider>
                                                </LocationProvider>
                                            </PdpProvider>
                                        </CheckoutProvider>
                                    </CartInitializer>
                                </CartProvider>
                            </FilterProvider>
                        </UserContextProvider>
                    </HelmetProvider>
                </GoogleTagManagerEvents>
            </ApolloProvider>
        </Sentry.ErrorBoundary>
    );
};

export default App;
