import { convertPhoneNumberAsPerSbmChannel } from '../../components/global/utils/commonUtils';
import { logError } from '../../components/global/utils/logger';
import { STORAGE_CONFIG } from '../../constants/storageConfig';

export const updatePhoneNumbers = () => {
    try {
        // Get all anchor tags with class 'location__call'
        const phoneAnchors = document.querySelectorAll('a.location__call');

        if (phoneAnchors?.length === 0) {
            console.info('No phone number anchors found with class "location__call"');
            return;
        }

        // Process each anchor tag
        phoneAnchors?.forEach(anchor => {
            // Extract the current phone number from the href attribute or text content
            let phoneNumber = null;
            let originalFormat = null;

            // Check if phone number is in href (could be tel: or tel:// format)
            if (anchor?.href) {
                if (anchor?.href?.startsWith('tel://')) {
                    // Handle the tel:// format
                    phoneNumber = anchor?.href?.substring(6); // Remove 'tel://' prefix
                } else if (anchor?.href?.startsWith('tel:')) {
                    // Handle the tel: format
                    phoneNumber = anchor.href.substring(4); // Remove 'tel:' prefix
                }
            }

            // Store the original text format for later use
            if (anchor?.textContent) {
                originalFormat = anchor?.textContent;
            }

            // If we found a phone number, update it
            if (phoneNumber && (phoneNumber === '800-667-9328' || phoneNumber === '8006679328')) {
                const updatedPhoneNumber = convertPhoneNumberAsPerSbmChannel(phoneNumber);
                // Update href attribute preserving the original protocol format
                if (anchor?.href?.startsWith('tel://')) {
                    anchor.href = `tel://${updatedPhoneNumber}`;
                } else if (anchor?.href?.startsWith('tel:')) {
                    anchor.href = `tel:${updatedPhoneNumber}`;
                }
                // Update text content preserving the original formatting
                if (originalFormat) {
                    if (originalFormat.includes('-')) {
                        // Format the updated number with dashes to match original format
                        // Assuming North American format: XXX-XXX-XXXX
                        const digits = updatedPhoneNumber?.replace(/\D/g, '');
                        if (digits?.length === 10) {
                            anchor.textContent = `${digits?.substring(0, 3)}-${digits?.substring(
                                3,
                                6
                            )}-${digits?.substring(6)}`;
                        } else {
                            // For other length numbers, try to preserve original dash positions
                            const originalDashPositions = [];
                            for (let i = 0; i < originalFormat?.length; i++) {
                                if (originalFormat?.[i] === '-') {
                                    originalDashPositions?.push(i);
                                }
                            }

                            // Apply dashes at similar positions
                            let formattedNumber = digits;
                            for (let i = originalDashPositions?.length - 1; i >= 0; i--) {
                                const position = Math.min(originalDashPositions[i], formattedNumber?.length);
                                if (position > 0 && position < formattedNumber?.length) {
                                    formattedNumber =
                                        formattedNumber?.slice(0, position) + '-' + formattedNumber?.slice(position);
                                }
                            }
                            anchor.textContent = formattedNumber;
                        }
                    } else {
                        // If original was just digits, keep it that way
                        anchor.textContent = updatedPhoneNumber?.replace(/-/g, '');
                    }
                }
            }
        });

        console.info(`Updated ${phoneAnchors?.length} phone number anchors`);
    } catch (error) {
        logError(error, false, 'updatePhoneNumbers', []);
    }
};

window.addEventListener('load', function () {
    let gtmDomTriggered = false; // Prevents duplicate execution

    const checkForGtmDom = () => {
        if (!gtmDomTriggered && window.dataLayer.some(event => event.event === 'gtm.dom')) {
            gtmDomTriggered = true; // Set flag to avoid multiple executions
            updatePhoneNumbers();
        }
    };

    // Observe `dataLayer` and execute only once
    window.dataLayer.push({
        event: 'gtmListenerSetup',
        eventCallback: function () {
            checkForGtmDom(); // Ensures it runs immediately if gtm.dom is already fired
        }
    });
});