import React, { memo, useEffect, useState } from 'react';
import moment from 'moment';
import { bool, func, instanceOf, oneOfType, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { useCartState } from '../../../contexts/cart';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Ecommerce';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { MEDIA_TYPE } from '../../../constants/screenConstants';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import { useRentalLocation } from '../hooks/useRentalLocation';
import { useCheckAuthorityType } from '../../../hooks/useCheckUser';
import { useCapAnalytics } from '../hooks/useCapAnalytics';
import useMedia from '../../../hooks/useMedia';
import useModalAnalytics from '../../../hooks/analytics/useModalAnalytics';
import { AUTHORITY_TYPE } from '../../global/constants';
import RangePickerInput from '../../global/modules/rangepicker/RangePickerInput';
import UnavailableRental from '../../global/modules/unavailableRental/UnavailableRental';
import { logError } from '../../global/utils/logger';
import { useFilterState } from '../filterContext';
import { useCapUtils } from '../hooks/useCapUtils';
import { getDateDiffInHrs } from '../utils/atputils';
import './capDatePicker.scss';

const CapDatePicker = ({
    className,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    userType,
    isLabelVisibleInModal,
    showIconWithLabel,
    dynamicWidth,
    isWrapperClickable,
    formInteractionStartedEventAnalytics,
    triggerFormInteractedEvent
}) => {
    const capIntl = useIntl();
    const mediaType = useMedia();
    const [isOpen, setIsOpen] = useState(false);
    const [isEndDateEmpty, setIsEndDateEmpty] = useState(false);
    const [isStartDateEarly, setIsStartDateEarly] = useState(false);
    const [rangeState, setRangeState] = useState([
        {
            key: 'selection',
            startDate: startDate ? new Date(startDate) : new Date(),
            endDate: endDate ? new Date(endDate) : new Date(),
            color: '#F2F2F2'
        }
    ]);
    const [{ calendarDateInteraction }] = useCartState();
    const [{ editViewSource, isEditView }, dispatch] = useFilterState();
    const [{ sendAnalyticsDatePickerViewEvent }] = useModalAnalytics();
    const { handleDateEventsInteraction, sendDatePickerModalEvents } = useCapAnalytics();
    const { getCapUserDetails, onEditViewSourceMatch, handleResetClick, onRangePickerToggle } = useCapUtils();
    const { checkIfDayDisabled } = useRentalLocation();
    const [showWarning, setShowWarning] = useState(false);
    const startDateLabel = getCapUserDetails()?.startDateText || capIntl.formatMessage({ id: 'cap:start-date' });
    const endDateLabel = getCapUserDetails()?.endDateText || capIntl.formatMessage({ id: 'cap:end-date' });
    const [showCustomFocus, setShowCustomFocus] = useState(false);
    const authorityType = useCheckAuthorityType();
    const isP2P = [AUTHORITY_TYPE.P2P].includes(authorityType);

    useEffect(() => {
        if (mediaType !== MEDIA_TYPE.DESKTOP) {
            if (editViewSource === VARIABLE_CONFIG.CAP_VIEW_MODE.DATE_VIEW) {
                setShowCustomFocus(true);
            }
        }
    }, [editViewSource]);

    useEffect(() => {
        if (startDate && endDate) {
            setShowCustomFocus(false);
        }
    }, [startDate, endDate]);

    const updateDateContext = (startDate, endDate) => {
        const diffInHours = getDateDiffInHrs(rangeState[0]?.startDate, moment().format('YYYY-MM-DDTHH:mm:ss'));
        if (
            rangeState[0]?.startDate != '' &&
            new Date(rangeState[0]?.startDate).getTime() == new Date(rangeState[0]?.endDate).getTime()
        ) {
            setShowWarning(true);
            return false;
        } else if (rangeState[0]?.endDate == '' || rangeState[0]?.startDate == '') {
            setIsEndDateEmpty(true);
            setShowWarning(true);
            return false;
        } else if (diffInHours <= 48 && isP2P) {
            setIsStartDateEarly(true);
            setShowWarning(true);
            return false;
        } else {
            setStartDate(isNaN(new Date(startDate)?.getDate()) ? '' : startDate?.toString());
            setEndDate(isNaN(new Date(endDate)?.getDate()) ? '' : endDate?.toString());
            return true;
        }
    };

    const handleToggle = isDateClick => {
        try {
            if (!isOpen) {
                handleDatePickerViewAnalytics({ isDateClick });
            }
            if (isOpen && rangeState?.[0]?.startDate != '' && rangeState?.[0]?.endDate == '') {
                setIsEndDateEmpty(true);
                setShowWarning(true);
                return;
            }
            setIsOpen(!isOpen);
            onRangePickerToggle(!isOpen);
            setShowWarning(false);
            setIsEndDateEmpty(false);
        } catch (error) {
            logError(error, false, 'isDateClick');
        }
    };

    const handleDatePickerViewAnalytics = ({ isDateClick }) => {
        const dateType =
            isDateClick === VARIABLE_CONFIG.EVENT.START_DATE_CLICK
                ? EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_START_DATE_FORM_FIELD
                : EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_END_DATE_FORM_FIELD;
        formInteractionStartedEventAnalytics(dateType);
        sendAnalyticsDatePickerViewEvent(isDateClick);
        triggerFormInteractedEvent(dateType);
    };

    return (
        <RangePickerInput
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            selectedStartDate={startDate}
            selectedEndDate={endDate}
            setSelectedStartDate={setStartDate}
            setSelectedEndDate={setEndDate}
            isLabelVisibleInModal={isLabelVisibleInModal}
            className={`${className} ${userType === USER_TYPE.CREDIT && 'credit-user'} ${
                showCustomFocus ? 'date-picker-focus' : ''
            }`}
            checkIfDayDisabled={checkIfDayDisabled}
            showIconWithLabel={showIconWithLabel}
            showWarning={showWarning}
            setShowWarning={setShowWarning}
            handleToggle={handleToggle}
            renderWarningComponent={
                <UnavailableRental
                    showWarning={showWarning}
                    isOpen={isOpen}
                    handleToggle={handleToggle}
                    isEndDateEmpty={isEndDateEmpty}
                    isStartDateEarly={isStartDateEarly}
                />
            }
            isEndDateEmpty={isEndDateEmpty}
            setIsEndDateEmpty={setIsEndDateEmpty}
            isStartDateEarly={isStartDateEarly}
            setIsStartDateEarly={setIsStartDateEarly}
            rangeState={rangeState}
            setRangeState={setRangeState}
            endDateLabel={endDateLabel}
            showOnDrawerForTablet
            hideBackButton
            saveOnSelectDate={true}
            pickerOverlayClass="picker-overlay"
            startDateLabel={startDateLabel}
            dynamicWidth={dynamicWidth}
            isWrapperClickable={isWrapperClickable}
            handleDatesOnDoneClick={updateDateContext}
            handleResetClick={handleResetClick}
            editViewSource={editViewSource}
            handleDateEventsInteraction={handleDateEventsInteraction}
            sendDatePickerModalEvents={sendDatePickerModalEvents}
            sendDatePickerViewAnalytics={handleDatePickerViewAnalytics}
            isEditView={isEditView}
            calendarDateInteraction={calendarDateInteraction}
            mediaType={mediaType}
            onEditViewSourceMatch={onEditViewSourceMatch}
            onRangePickerToggle={onRangePickerToggle}
        />
    );
};

CapDatePicker.propTypes = {
    className: string,
    startDate: oneOfType([instanceOf(Date), string]),
    endDate: oneOfType([instanceOf(Date), string]),
    setStartDate: func,
    setEndDate: func,
    userType: string,
    isLabelVisibleInModal: bool,
    showIconWithLabel: bool,
    dynamicWidth: bool,
    isWrapperClickable: bool,
    formInteractionStartedEventAnalytics: func,
    triggerFormInteractedEvent: func
};

CapDatePicker.defaultProps = {
    className: '',
    startDate: '',
    endDate: '',
    setStartDate: () => {},
    setEndDate: () => {},
    userType: '',
    isLabelVisibleInModal: false,
    showIconWithLabel: false,
    dynamicWidth: false,
    isWrapperClickable: false,
    formInteractionStartedEventAnalytics: () => {},
    triggerFormInteractedEvent: () => {}
};

export default memo(CapDatePicker);
