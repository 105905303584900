import { PDP_GET_RATES, PDP_SET_PRODUCT, SET_ALTERNATE_INVENTORY, SET_PARENT_LINK, SET_RATES_LOADING_FLAG } from "./constants";

export const pdpActions = (state, action) => {
    switch (action.type) {
        case PDP_GET_RATES:
            return {
                ...state,
                rates: action.rates
            };
        case PDP_SET_PRODUCT:
            return {
                ...state,
                item: {
                    ...(action.item || {}),
                    qty: action?.item?.qty ?? state?.item?.qty
                }
            };
        case SET_RATES_LOADING_FLAG:
            return {
                ...state,
                isRatesLoading: action.isRatesLoading
            };
        case SET_ALTERNATE_INVENTORY:
            return {
                ...state,
                alternateInventory: action.alternateInventory
            };
        case SET_PARENT_LINK:
            return {
                ...state,
                parentLink: action.parentLink
            };
        default:
            return state;
    }
};