import React, { memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { usePageType } from '../../../hooks/usePageType';
import useAnalytics from '../../../hooks/analytics/useAnalytics';
import ArrowRight from '../../../resources/images/arrow-ios-right.svg';
import AutoCompleteDropdown from '../../global/atoms/autoComplete';
import { isAtpCheckRequired, isNearByPcsRequired } from '../../global/utils/commonUtils';
import { updateQueryParam } from '../utils/searchUtils';
import { customSearchBox, relinfos_searchbox_ctlr } from '../controllers/searchresultscontroller';
import '../typahead/typeahead.scss';
import { logError } from '../../global/utils/logger';
import { buildContextBasedOnCompanyId, updateContextBasedOnCompanyId } from '../Engine';
import { useCartState } from '../../../contexts/cart';
import { useFilterState } from '../../cap';
import { SET_SHOW_UNAVAILABLE_ITEMS } from '../../../aem-core-components/actions/constants';
import { ENV_CONFIG } from '../../../constants/envConfig';

function Typeahead(props) {
    const { source } = props;
    const [{}, filterDispatch] = useFilterState();
    const [searchState, setSearchState] = useState(customSearchBox.state);
    const [relInfoState, setRelInfoState] = useState(relinfos_searchbox_ctlr.state);
    const [isOpen, setIsOpen] = useState(true);
    const [rangeScroll, setRangeScroll] = useState(false);
    const [groupResultRange, setGroupResultRange] = useState([]);
    const intl = useIntl();
    const isDevelopment = process.env.NODE_ENV === 'development';
    const {
        productslabel,
        productscount,
        categorieslabel,
        categoriescount,
        searchtermslabel,
        searchtermscount,
        relatedinfolabel,
        relatedinfocount,
        searchplaceholderlabel
    } = ENV_CONFIG.SEARCH_COVEO || null;
    const { sendEventsForClick } = useAnalyticsContext();
    const pageType = usePageType();
    const companyID = localStorage.getItem('companyID') || '';
    const [{ handleAnalyticsOnFocus }] = useAnalytics();
    const [{ nearbyPCs }] = useCartState();

    useEffect(() => {
        if (companyID) {
            buildContextBasedOnCompanyId();
        }
    }, [companyID]);

    useEffect(() => {
        setGroupResultRange([
            { group: productslabel || 'Products', key: 'ProductSuggestions', range: productscount || 4 },
            { group: categorieslabel || 'Categories', key: 'L2Categories', range: categoriescount || 3 },
            { group: searchtermslabel || 'Search Terms', key: 'default', range: searchtermscount || 3 },
            {
                group: relatedinfolabel || 'Related Info',
                key: 'RelatedContentSuggestions',
                range: relatedinfocount || 3
            }
        ]);
    }, [
        productslabel,
        productscount,
        categorieslabel,
        categoriescount,
        searchtermslabel,
        searchtermscount,
        relatedinfolabel,
        relatedinfocount
    ]);

    const updateState = () => {
        setSearchState(customSearchBox?.state);
        setRelInfoState(relinfos_searchbox_ctlr.state);
    };
    useEffect(() => {
        customSearchBox.subscribe(updateState);
        relinfos_searchbox_ctlr.subscribe(updateState);
    }, []);
    //TODO=to be removed post testing
    /*   useEffect(() => {
          if (searchState && window.location.pathname.includes('search')) {
              const params = new URLSearchParams(window.location.search);
              const search = params.get('q');
              if (search) {
                  //  relinfos_searchbox_ctlr.updateText(search);
                  // submitController(); //handled in search results after facets ipdated
              }
          }
      }, []); */
    useEffect(() => {
        const handleClickOutside = e => {
            if (!e.target.matches('.formContainer')) {
                setIsOpen(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
    }, [isOpen]);

    const handleUpdateText = val => {
        updateQueryParam(val);
        customSearchBox.updateText(val);
        relinfos_searchbox_ctlr.updateText(val);
    };

    const submitController = () => {
        filterDispatch({ type: SET_SHOW_UNAVAILABLE_ITEMS, showUnavailableItems: false });
        updateContextBasedOnCompanyId(
            [],
            [
                {
                    key: 'nearbypcs',
                    val: isAtpCheckRequired()
                }
            ],
            isNearByPcsRequired(false)
        );
        customSearchBox.submit();
        relinfos_searchbox_ctlr.submit();
    };

    const handleItemClick = async item => {
        var searchTerm = document.getElementsByClassName('autocomplete__input')[0]?.firstChild?.value;
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.SEARCH_BAR,
                `${VARIABLE_CONFIG.EVENT_ACTION.SELECT} ${item?.group}`,
                `${searchTerm} :: ${pageType}`
            );
        } catch (error) {
            logError(error, false, 'handleItemClick');
        }
        if (item?.group.toLowerCase().includes('product')) {
            window.location.href = item?.result?.raw?.ec_product_url;
        } else if (item?.group.toLowerCase().includes('default')) {
            handleUpdateText(item?.rawValue);
            submitController();
            // window.location.href = `${config?.pagePaths?.searchPage}?q=${item?.rawValue}`;
        } else if (item?.group.toLowerCase().includes('related')) {
            window.location.href = item?.result?.ClickUri;
        } else if (item?.group.toLowerCase().includes('categories')) {
            window.location.href = item?.path;
        }
        setIsOpen(false);
    };
    const onEnterKeyPressed = (e, item) => {
        submitController();
        setIsOpen(false);
    };

    const renderGroup = group => {
        if (groupResultRange) {
            const currentGroup = groupResultRange.filter(result =>
                group?.toLowerCase().includes(result?.key.toLowerCase())
            );
            if (currentGroup.length > 0) return currentGroup[0].group;
            else return '';
        }
    };

    const getSuggestions = () => {
        if (searchState?.value) {
            const suggestion = searchState?.groups?.filter(
                (item, index) => searchState?.groups.indexOf(item) === index
            );
            suggestion.forEach(item => {
                if (item.name.toLowerCase().includes('product')) {
                    item.pos = 1;
                } else if (item.name.toLowerCase().includes('categories')) {
                    item.pos = 2;
                } else if (item.name.toLowerCase().includes('default')) {
                    item.pos = 3;
                } else if (item.name.toLowerCase().includes('related')) {
                    item.pos = 4;
                }
            });
            suggestion.sort((group1, group2) => group1.pos - group2.pos);
            return suggestion.map(item => item.name);
        } else return [];
    };
    const renderSuggestion = group => {
        var suggestions = searchState?.newSuggestions?.filter(item => item.group === group);
        if (groupResultRange) {
            const currentGroup = groupResultRange.filter(result =>
                group.toLowerCase().includes(result?.key.toLowerCase())
            );
            if (currentGroup.length > 0) suggestions.splice(currentGroup[0]?.range, suggestions?.length - 1);
        }
        return suggestions?.length > 0 && isOpen ? ( // toggle here
            <>
                <li className="borderBottom">
                    <span className="groupHeader">{renderGroup(group)}</span>
                    {suggestions.map((item, index) => (
                        <div
                            tabIndex={0}
                            className="listItems"
                            onClick={() => handleItemClick(item)}
                            key={`${item?.id}${index}`}>
                            <div
                                tabIndex={-1}
                                onKeyPress={e => onEnterKeyPressed(e, item)}
                                id={item.highlightedValue}
                                aria-selected="false"
                                role="option"
                                dangerouslySetInnerHTML={{ __html: item.highlightedValue }}></div>
                            <span>
                                <ArrowRight />
                            </span>
                        </div>
                    ))}
                </li>
            </>
        ) : null;
    };
    const handleChange = e => {
        handleUpdateText(e.target.value);
        setIsOpen(true);
    };

    const handleSelectedVal = selectedText => {
        handleUpdateText(selectedText);
        submitController();
        setIsOpen(false);
    };
    const handleReset = () => {
        handleUpdateText('');
        submitController();
        setIsOpen(true);
    };

    return (
        <div className="formContainer">
            <AutoCompleteDropdown
                placeholderText={searchplaceholderlabel || intl.formatMessage({ id: 'location:search-placeholder' })}
                showResetBtn={true}
                onChange={handleChange}
                onSelect={handleSelectedVal}
                onReset={handleReset}
                onEnterKeyPress={onEnterKeyPressed}
                showSearchIcon={true}
                isDropDownOpen={isOpen}
                renderSuggestion={renderSuggestion}
                suggestions={getSuggestions()}
                value={searchState.value}
                autoCompleteInputClass={'cmp-Field__field__input'}
                autoCompleteWrapperClass={'header-autocomplete'}
                source={VARIABLE_CONFIG.PLACEMENT.HEADER}
                handleEcomLocationAnalyticsOnFocus={() => handleAnalyticsOnFocus(source)}
            />
        </div>
    );
}

export default memo(Typeahead);
