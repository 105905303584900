import axios from 'axios';
import { cookieValue } from '../aem-core-components/utils/cookieUtils';
import { getApiConfigByEnv } from '../components/global/utils/commonUtils';
import { generateCorrelationId, logError } from '../components/global/utils/logger';
import { STORAGE_CONFIG } from '../constants/storageConfig';
import { useAuthorityToken } from '../aem-core-components/utils/hooks';

const config = getApiConfigByEnv();
const instance = axios.create({
    ...config
});

const [accessToken] = useAuthorityToken('accesstoken');
const [refreshToken] = useAuthorityToken('refreshtoken');

var getAuthToken = () => {
    var token = '';
    token = JSON.parse(localStorage.getItem('user-login'))?.accessToken || accessToken || '';

    return `Bearer ${token}`;
};

//TODO
instance.interceptors.request.use(
    req => {
        req.headers = {
            ...req.headers,
            'x-correlation-Id': generateCorrelationId(),
            companyId:
                typeof req.headers.companyId == 'undefined'
                    ? parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1
                    : req.headers.companyId,
            Authorization: getAuthToken()
        };
        return req;
    },
    err => {
        return Promise.reject(err);
    }
);
instance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (Boolean(error?.response?.config?.headers['isSilentOnError']) === true) {
            logError(error?.response);
        } else if (error?.response?.status >= 500) {
            //internal server error
            logError(error?.response, true);
        } else if (error?.response?.status === 429) {
            logError(error?.response, true);
        } else if (error?.response?.status === 401 && !(accessToken && refreshToken)) {
            logError(error?.response, false);
            return Promise.reject(error);
        } else {
            logError(error);
            return Promise.reject(error);
        }
    }
);

export default instance;
