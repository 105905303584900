import get from 'lodash/get';
import moment from 'moment';
import * as moment2 from 'moment-timezone';
import { createIntl, createIntlCache } from 'react-intl';
import cloneDeep from 'lodash/cloneDeep';
import { ADDRESS_COMPONENTS, ADDRESS_COMPONENT_TYPE } from '../../../constants/cartConstants';
import { cookieValue } from '../../../aem-core-components/utils/cookieUtils';
import { getExcludedPCfromList } from '../../cap/utils/capHelper';
import config from '../../../components/App/config';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { CATEGORY_PAGE_TEMPLATE_STRING } from '../../../constants/screenConstants';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import i18Messages from '../../../i18n/en.json';
import { QTY_LIMIT, YES } from '../../pdp/constants';
import { customSearchBox } from '../../search/controllers/searchresultscontroller';
import { RECENTLY_VIEWED } from '../modules/recentlyViewed/constants';
import { isValidString, logError } from './logger';
import { AUTHORITY_TYPE, ENV_CONFIGS } from '../constants';
import { useCheckAuthorityType, useCheckUser } from '../../../hooks/useCheckUser';
import { QUOTE_DETAIL_RECORD_TYPE_PUNCHOUT } from '../../quotes/constants';
import { GENERAL_EQUIPMENT_AND_TOOLS } from '../../location/constants';
import isObjectWithKeys from '../../../aem-core-components/utils/isObjectWithKeys';
import { EXPANDED_TIER_ONE_SEARCH_RADIUS } from '../../cap/constants';

const stateJson = require('../../../constants/config.json');
const isDevelopment = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';
const authorityType = useCheckAuthorityType();

export const EMAIL_REGEX = /^[A-Za-z0-9]+[\w.-]*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*\.[A-Za-z]{2,6}$/;
export const PHONE_REGEX = /^([0-9]{3})[-]?[ ]?([0-9]{3})[-]?([0-9]{4})$/;
export const NAME_REGEX = /^[A-Za-z-]{0,50}$/;
export const LETTER_REGEX = /(?=.*[A-Za-z])/;
export const DIGIT_REGEX = /\D/g;
export const CITY_REGEX = /^.{0,50}$/;
export const consecutiveSpecialCharRegex = /\W\W/;
export const ZIP_REGEX = /^\d{5}(?:[-\s]\d{4})?$/;
export const ACCESS_NOTES_REGEX = /^[a-zA-Z0-9- ]{0,160}$/;
export const PRIMARY_CONTACT_REGEX = /^[A-Za-z- ]{0,50}$/;
export const PO_NUMBER_REGEX = /^[a-zA-Z0-9-]{0,26}$/;
export const POSTAL_REGEX = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
export const CUSTOM_FIELDS_REGEX = /[&%=]/;
export const JOBSITE_FIELDS_REGEX = /^[A-Za-z0-9& -]{0,30}$/;
export const DELAY_TIME = 200;
export const frontendConfig = ENV_CONFIG.FRONTEND_CONFIG;
export const environment = ENV_CONFIG.ENVIRONMENT;
export const endPoint = ENV_CONFIG.ENDPOINT;
export const googleApiKey = ENV_CONFIG.GOOGLE_API_KEY;
export const validStateList = ENV_CONFIG.VALID_STATE_LIST;
export const cacheItemsRatesLimit = 150;
export const isWebView = navigator.userAgent.includes('wv');
export const punchoutPath = '/procure-to-pay/punchout';
export const MIN_SEARCH_QUERY_LENGTH = 3;
export const MIN_ERROR_MSG_LENGTH = 2;
export const DEFAULT_ACCOUNT_ROW_HEIGHT = 126;
export const DEFAULT_ACCOUNT_ROW_HEIGHT_P2P = 126;
export const JOBSITE_SEARCH_LENGTH = 10;
export const SEARCH_VIEW_LENGTH = 20;

export const validateEmail = email => {
    if (consecutiveSpecialCharRegex.test(String(email))) {
        return false;
    }
    return EMAIL_REGEX.test(String(email).toLowerCase());
};

export const validatePhone = phone => PHONE_REGEX.test(String(phone));

export const validateName = name => NAME_REGEX.test(String(name)) && LETTER_REGEX.test(String(name));

export const validateJobSite = jobsite => JOBSITE_FIELDS_REGEX.test(String(jobsite));

export const validateZip = zip => {
    const companyID = parseInt(localStorage.getItem('companyID')) || 1;
    if (companyID === 2) {
        if (zip?.indexOf('000') > -1) {
            console.log('call validateZip if');
            return true;
        } else {
            console.log('call validateZip else');
            return true;
        }
    } else {
        return ZIP_REGEX.test(String(zip));
    }
};
export const validateCustomField = text => CUSTOM_FIELDS_REGEX.test(String(text));

export const validateCity = city => CITY_REGEX.test(String(city));

export const validateAccessNotes = accessNotes => ACCESS_NOTES_REGEX.test(String(accessNotes));

export const validatePONumber = poNumber => PO_NUMBER_REGEX.test(String(poNumber));

export const validatePrimaryContact = primaryContact => PRIMARY_CONTACT_REGEX.test(String(primaryContact));

export const formatMobileNumber = value => {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');
    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early

    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export const convertDateToMomentDateTime = (date, selTime) => {
    let dateMoment = date ? moment(date) : moment();
    let pickupTime = '';
    let currTime = moment();
    if (selTime) {
        pickupTime = moment(selTime, 'h:mm A');
    }
    const dateAndTime = dateMoment.set({
        hour: pickupTime ? pickupTime.get('hour') : currTime.get('hour'),
        minute: pickupTime ? pickupTime.get('minute') : currTime.get('minute'),
        second: pickupTime ? pickupTime.get('second') : currTime.get('second'),
        millisecond: pickupTime ? pickupTime.get('millisecond') : currTime.get('millisecond')
    });
    // return dateAndTime.utc().format();
    let dateTimeFormatted = moment(dateAndTime?.toDate()).format('YYYY-MM-DDTHH:mm:ss[Z]');
    return dateTimeFormatted;
};

export const getTimeFromCity = timezone => {
    const offset = getNormalizedUtcOffset(timezone);
    const d = new Date();
    const utc = d.getTime() + d.getTimezoneOffset() * 60000;
    return new Date(utc + 3600000 * offset);
};

/**
 * Returns the UTC offset for the given timezone
 * @param timezone Example: America/New_York
 * @returns Offset value of passed timezone
 */
const getNormalizedUtcOffset = timezone => {
    const momentTimezone = moment2.tz(timezone);
    if (!momentTimezone) {
        return null;
    }
    let offset = momentTimezone.utcOffset();
    if (momentTimezone.isDST()) {
        // utcOffset will return the offset normalized by DST. If the location
        // is in daylight saving time now, it will be adjusted for that. This is
        // a NAIVE attempt to normalize that by going back 1 hour
        // TODO: Need to verify DST post API update
        // offset -= 60;
    }
    return offset / 60;
};

export const formatCurrency = value => {
    const amount = parseInt(value);
    if (amount === 0) {
        return '$' + value;
    }
    return currencyToLocale(value, 2, 2);
};

export const currencyToLocale = (value, maxFractionDigits, minFractionDigits) => {
    if (value && value !== '-' && !isNaN(value)) {
        // If the value is negative, the -sign will be removed from the value and it will be wrapped inside parenthesis.
        // e.g input: '-1069.21' output- '($1,069.21)'
        if (Number(value) < 0) {
            return (
                '($' +
                Math.abs(value).toLocaleString('en-US', {
                    maximumFractionDigits: maxFractionDigits,
                    minimumFractionDigits: minFractionDigits
                }) +
                ')'
            );
        }
        if (Number.isInteger(Number(value))) {
            return (
                '$' +
                Number(value).toLocaleString('en-US', {
                    maximumFractionDigits: maxFractionDigits,
                    minimumFractionDigits: minFractionDigits
                })
            );
        }
        return '$' + Number(value).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    } else {
        return '-';
    }
};

export const getEnv = () => {
    var host = window.location.hostname;
    if (host.includes('localhost')) {
        return 'localhost';
    } else if (host.includes('dev-www')) {
        return 'development';
    } else if (host.includes('qa-www')) {
        return 'qa';
    } else if (host.includes('uat-www')) {
        return 'uat';
    } else if (host.includes('stage-www')) {
        return 'stage';
    } else if (host === 'www.sunbeltrentals.com') {
        return 'production';
    }
};

const getFallbackConfig = () => {
    const env = getEnv();
    switch (env) {
        case VARIABLE_CONFIG.DOMAIN.PRODUCTION:
            return ENV_CONFIGS[VARIABLE_CONFIG.DOMAIN.PRODUCTION];
        case VARIABLE_CONFIG.DOMAIN.STAGING:
            return ENV_CONFIGS[VARIABLE_CONFIG.DOMAIN.STAGING];
        default:
            return ENV_CONFIGS[VARIABLE_CONFIG.DOMAIN.QA];
    }
};

const fallbackConfig = getFallbackConfig();

export const getApiConfigByEnv = () => {
    const muleDomain = frontendConfig?.muledomain || fallbackConfig?.muledomain;
    const clientId = frontendConfig?.clientid || fallbackConfig?.clientId;
    const clientSecret = frontendConfig?.clientsecretcode || fallbackConfig?.clientsecretcode;
    const isP2POrPunchout = [AUTHORITY_TYPE.P2P, AUTHORITY_TYPE.PUNCHOUT].includes(authorityType);
    return {
        baseURL: muleDomain, // api domain
        timeout: 30000,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            client_secret: clientSecret,
            client_id: clientId,
            'Request-Id': '12323sunbelt',
            channel: isP2POrPunchout ? 'PUNCHOUT' : 'WEBAPP'
        }
    };
};

export const generateSKU = (catId, classId) => {
    const catRental = ('000' + catId).slice(-3);
    const classRental = ('0000' + classId).slice(-4);
    return catRental + classRental;
};

export const PRODUCT_TILE_MOUNT_REACT = 'product__tile__react';

export const getProductAssetsList = () => {
    let aemProducts = document.querySelectorAll(config.mountingPoints.addToCartTile);
    let reactProducts = document.querySelectorAll(`.${PRODUCT_TILE_MOUNT_REACT}`);
    let products = [...aemProducts, ...reactProducts];
    var assets = [];
    for (let i = 0; i < products.length; i++) {
        if (products[i]?.dataset?.productdata) {
            var productData = JSON.parse(products[i]?.dataset.productdata);
            assets.push(productData.catclass);
        }
    }
    return [...new Set(assets)].filter(item => item);
};

export const formatAccountProjects = (data = []) => {
    let replaceObj = { '(': '', ')': '-', ' ': '' };
    return data?.map(item => {
        let delInstructions =
            `${item?.deliveryInstructions1}${item?.deliveryInstructions2}${item?.deliveryInstructions3}${item?.deliveryInstructions4}`.replace(
                /\s+$/,
                ''
            );
        return {
            jobNumber: item?.projectId?.trim(),
            jobName: sentenceCase(item?.address[0]?.attn?.trim()),
            rmJobsiteId: item?.jobsiteId,
            account: item?.contact?.id,
            companyID: item?.companyId || 1,
            address1: sentenceCase(item?.address[0]?.line1?.trim()) || sentenceCase(item?.Location?.trim()),
            address2: item?.address[0]?.line2?.trim(),
            city: sentenceCase(item?.address[0]?.city?.trim()),
            state: item?.address[0]?.state?.trim(),
            zip: item?.address[0]?.zip?.trim(),
            latitude: item?.address[0]?.latitude,
            longitude: item?.address[0]?.longitude,
            locationPC: parseInt(item?.address[0]?.locationPC),
            salesRepId: item?.salesRepId,
            isActive: item?.isActive,
            jobKey: item?.projectKey,
            contactName: item?.contact?.name,
            contactPhone: item?.contact?.phone?.replace(/[() ]/g, item => replaceObj[item]),
            hasEquipment: false,
            equipmentCount: item?.equipmentCount,
            deliveryInstructions: delInstructions,
            customerPO: item?.customerPO?.trim(),
            CJDLVY: item?.CJDLVY,
            location: item?.Location?.trim(),
            rType: item?.Rtype
        };
    });
};

export const makeProductURL = (productUrl = '') => {
    return window.location.origin + productUrl;
};

export const recentlyViewedTracker = productId => {
    const recentProducts = localStorage.getItem(RECENTLY_VIEWED);
    if (recentProducts) {
        let productList = JSON.parse(recentProducts);
        productList = productList?.filter(value => {
            if (value !== productId) {
                return value;
            }
        });
        productList.unshift(productId);
        localStorage.setItem(RECENTLY_VIEWED, JSON.stringify(productList));
    } else {
        const productArray = [];
        productArray[0] = productId;
        localStorage.setItem(RECENTLY_VIEWED, JSON.stringify(productArray));
    }
};

const cache = createIntlCache();
export const intl = createIntl({ locale: 'en', messages: i18Messages }, cache);

export const getURLParams = () => {
    const url = new URL(window.location.href);

    let regex = /[?&]([^=#]+)=([^&#]*)/g,
        params = {},
        match;
    while ((match = regex?.exec(url))) {
        params[match[1]] = match[2];
    }
    return params;
};
export const isPaymentSectionVisited = () => {
    const params = getURLParams();
    if (params?.TRANSUCCESS || params?.CUSTOM2) {
        return true;
    } else {
        return false;
    }
};
export const getProjectsFromCookie = () => {
    const settingsCookie = cookieValue('Settings');
    const projectObj = {};
    if (settingsCookie) {
        const projectsInfo = settingsCookie.split('&');
        for (let i = 0; i < projectsInfo.length; i++) {
            const [key, value] = projectsInfo[i].split('=');
            projectObj[key] = decodeURIComponent(value);
        }
    }
    return projectObj;
};

export const getUserAccountFromCookies = () => {
    const projectObj = getProjectsFromCookie();
    return {
        accountName: projectObj?.CurrentWynneAccountName || '',
        accountNumber: parseInt(projectObj?.CurrentWynneAccount) || '',
        accountStatus: projectObj?.CurrentWynneAccountStatus || ''
    };
};

export const getInitialProjectStateFromCookie = () => {
    const isNewAddress = JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS)) || false;
    const projectInfoCookies = getProjectsFromCookie();
    const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
    const selectedAccID = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.SELECTED_ACCOUNT_ID);
    let setProjectFromStorage = false;
    if (projectInfoCookies?.CurrentWynneAccount && !isNewAddress) {
        if (projectInfoCookies?.CurrentWynneAccount == selectedAccID) {
            setProjectFromStorage = true;
        }
    }
    if (setProjectFromStorage) {
        return {
            locationPC: overridePC?.pc || parseInt(projectInfoCookies?.locationPC) || null,
            projectName: projectInfoCookies?.projectName || '',
            projectAddress1: projectInfoCookies?.SLA || '',
            projectAddress2: projectInfoCookies?.projectAddress2 || '',
            selectedProjectJobId: projectInfoCookies?.CurrentJobSite || '',
            selectedRMJobId: projectInfoCookies?.RMJobsiteId || '',
            selectedProjectLatititude: parseFloat(projectInfoCookies?.SLLat) || '',
            selectedProjectLongitude: parseFloat(projectInfoCookies?.SLLong) || '',
            selectedProjectState: projectInfoCookies?.SLS || '',
            selectedProjectCity: projectInfoCookies?.SLC || '',
            selectedProjectZip: projectInfoCookies?.SLZ || '',
            primaryContactName: projectInfoCookies?.primaryContactName || '',
            phoneNumber: projectInfoCookies?.phoneNumber || '',
            accessNotes: projectInfoCookies?.accessNotes || '',
            poNumber: projectInfoCookies?.poNumber || '',
            isValueUpdated: false,
            CJDLVY: localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CJDLVY) || ''
        };
    } else {
        return {
            locationPC: null,
            projectName: '',
            projectAddress1: '',
            projectAddress2: '',
            selectedProjectJobId: '',
            selectedRMJobId: '',
            selectedProjectLatititude: '',
            selectedProjectLongitude: '',
            selectedProjectState: '',
            selectedProjectCity: '',
            selectedProjectZip: '',
            primaryContactName: '',
            phoneNumber: '',
            accessNotes: '',
            poNumber: '',
            isValueUpdated: false,
            CJDLVY: ''
        };
    }
};

export const isValidState = state => {
    if (validStateList?.includes(state)) {
        return true;
    } else {
        return false;
    }
};

export const deleteCookies = (exludeCookies = []) => {
    var allCookies = document.cookie
        .split(';')
        ?.filter(cookie => !exludeCookies.includes(cookie.split('=')[0]?.trim()));
    for (var i = 0; i < allCookies.length; i++)
        document.cookie =
            allCookies[i] + '=;expires=' + new Date(0).toUTCString() + '=; path=/; SameSite=None; Secure; Max-Age=0;';
};

export const reloadOnAccountChange = userType => {
    if (userType === USER_TYPE.CREDIT && window.location.href.includes('myaccount')) {
        window.location.reload();
    }
};
export const getRentalDuration = (startDate, endDate) => {
    const rentalDuration = new Date(endDate).getTime() - new Date(startDate).getTime();
    return rentalDuration / (1000 * 3600 * 24);
};
export const getPCfromList = pcList => {
    let cidPcList = [];
    let atpPcList = [];
    let pcDetails;
    const { pricingPcs, pickupStorePcs } = getExcludedPCfromList(pcList);

    for (let i in pricingPcs) {
        cidPcList.push(formatNearbyPC(pricingPcs[i]?.pc));
    }

    for (let i in pcList) {
        atpPcList.push(formatNearbyPC(pcList[i]?.pc));
    }

    const specialities = pcList?.find(
        item =>
            !item?.specialties ||
            item?.specialties?.length === 0 ||
            item?.specialties?.[0] === GENERAL_EQUIPMENT_AND_TOOLS
    );

    pcDetails = specialities || pcList?.[0];
    sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.CID_PC_LIST, JSON.stringify(cidPcList));
    sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ATP_PC_LIST, JSON.stringify(atpPcList));
    return { pcDetails, pickupStorePcs };
};

export const formatjobsiteZip = zipCode => {
    return (zipCode?.length === 3 ? zipCode + '000' : zipCode) || '';
};

export const getSignInUrl = (customRedirection = '', newAccount = false, includeReturnUrl = true) => {
    const returnUrl =
        window.location.pathname.indexOf(config.pagePaths.wiresCrossedPage) > -1 ? '/' : window.location.href;
    if (includeReturnUrl) {
        return isDevelopment
            ? `https://dev-www.sunbeltrentals.com/login/?returnUrl=${
                  customRedirection ? customRedirection : window.location.href
              }${newAccount ? '&newAccount=' + newAccount : ''}`
            : `${window.location.origin}/login/?returnUrl=${customRedirection || returnUrl}${
                  newAccount ? '&newAccount=' + newAccount : ''
              }`;
    } else {
        return isDevelopment
            ? `https://dev-www.sunbeltrentals.com/login/${newAccount ? '?newAccount=' + newAccount : ''}`
            : `${window.location.origin}/login/${newAccount ? '?newAccount=' + newAccount : ''}`;
    }
};

export const isGlobalRates = () => {
    if (window.location.pathname.includes('/search') || document.querySelector("meta[name='categoryTitle']")) {
        return false;
    }
    return true;
};

export const trapFocus = element => {
    var focusableEls = element.querySelectorAll(
        'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled]), h1, h2, h3, h4, h5, h6, strong, b'
    );
    var firstFocusableEl = focusableEls[0];
    var lastFocusableEl = focusableEls[focusableEls.length - 1];
    var KEYCODE_TAB = 9;
    element.addEventListener('keydown', function (e) {
        var isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB;
        if (!isTabPressed) {
            return;
        }
        if (e.shiftKey) {
            /* shift + tab */ if (document.activeElement === firstFocusableEl) {
                lastFocusableEl.focus();
                e.preventDefault();
            }
        } /* tab */ else {
            if (document.activeElement === lastFocusableEl) {
                firstFocusableEl.focus();
                e.preventDefault();
            }
        }
    });
};

export const determineCityComponent = addressComponents => {
    for (let index in addressComponents) {
        if (addressComponents[index]?.types?.includes(ADDRESS_COMPONENTS.LOCALITY)) {
            return addressComponents[index]?.longName;
        } else if (addressComponents[index]?.types?.includes(ADDRESS_COMPONENTS.AL3)) {
            return addressComponents[index]?.longName;
        } else if (addressComponents[index]?.types?.includes(ADDRESS_COMPONENTS.AL2)) {
            return addressComponents[index]?.longName;
        }
    }
    return '';
};

export const isAccountClosed = userAcc => {
    const S = userAcc?.accountStatus?.toLowerCase();
    const inactiveStatus = ['c', 'i', 's', 'h'];
    if (authorityType === AUTHORITY_TYPE.P2P) {
        return inactiveStatus.includes(S);
    }
    return inactiveStatus.includes(S) && !isValidString(userAcc?.isCorpLinkAccount);
};
export const isAccountBlocked = userAcc => {
    const S = userAcc?.accountStatus?.toLowerCase();
    const inactiveStatus = ['b', 'f'];
    if (authorityType === AUTHORITY_TYPE.P2P) {
        return inactiveStatus.includes(S);
    }
    return inactiveStatus.includes(S) && !isValidString(userAcc?.isCorpLinkAccount);
};
export const clearLocalStorage = (conserveKeys = []) => {
    const backupData = {};
    backupData[STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID] = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID);
    for (let key of conserveKeys) {
        backupData[key] = localStorage.getItem(key);
    }
    localStorage.clear();
    for (let key in backupData) {
        localStorage.setItem(key, backupData[key]);
    }
};
export const getStateList = () => {
    const companyID = parseInt(localStorage.getItem('companyID')) || '';
    const stateArray = companyID == 2 ? stateJson['STATES'][0]['CA'] : stateJson['STATES'][0]['US'];
    if (companyID == 2) {
        const validStateListArray = validStateList.split(',');
        return stateArray.filter(state => {
            if (validStateListArray.includes(state?.label) || validStateListArray.includes(state?.value)) {
                return state;
            }
        });
    }
    return stateArray;
};
export const getUserType = type => {
    if (type === 'cash') {
        return 'cash';
    } else if (type === 'credit' || type === 'employee') {
        return 'credit';
    } else {
        return 'guest';
    }
};
export const isCCPage = () => {
    const url = window.location.href?.toLowerCase();
    return (
        url.includes(config.pagePaths.myAccount) ||
        url.includes(config.pagePaths.webForms) ||
        url.includes(config.pagePaths.payment) ||
        url.includes(config.pagePaths.createAccountMain)
    );
};
export const isCheckoutPage = () => {
    try {
        // For P2P, we refer to the checkout page as the "create quote" page and the "edit quote" page.
        if (authorityType === AUTHORITY_TYPE.P2P) {
            const quotePagePaths = [config.pagePaths.p2pCreateQuote, config.pagePaths.p2pEditQuote];
            const isInQuotePagePaths = quotePagePaths.some(url => window.location.href.includes(url));
            return isInQuotePagePaths;
        }
        return window.location.href.includes(config.pagePaths.checkoutPage);
    } catch (error) {
        logError(error, false, 'isCheckoutPage');
    }
};
export const calcRQFlagStatus = flag => {
    const str = String(flag)?.toUpperCase();
    if (str === YES || str === '1') {
        return true;
    }
    return false;
};
export const checkIsRequestQuote = (showOnlineCatalog, disableAddToCart) => {
    if (calcRQFlagStatus(showOnlineCatalog) && !calcRQFlagStatus(disableAddToCart)) {
        return { showCTA: true, isRequestQuote: false };
    } else if (calcRQFlagStatus(showOnlineCatalog) && calcRQFlagStatus(disableAddToCart)) {
        return { showCTA: true, isRequestQuote: authorityType === AUTHORITY_TYPE.P2P ? false : true };
    } else if (!calcRQFlagStatus(showOnlineCatalog)) {
        return { showCTA: false, isRequestQuote: false };
    } else {
        return { showCTA: true, isRequestQuote: false };
    }
};

export const checkRequestQuoteForP2PATP = (showOnlineCatalog, disableAddToCart) => {
    if (calcRQFlagStatus(showOnlineCatalog) && !calcRQFlagStatus(disableAddToCart)) {
        return { showCTA: true, isRequestQuote: false };
    } else if (calcRQFlagStatus(showOnlineCatalog) && calcRQFlagStatus(disableAddToCart)) {
        return { showCTA: true, isRequestQuote: true };
    } else if (!calcRQFlagStatus(showOnlineCatalog)) {
        return { showCTA: false, isRequestQuote: false };
    } else {
        return { showCTA: true, isRequestQuote: false };
    }
};

export const getValidQty = (cartItems, qtyToAdd, productId) => {
    let result = { error: false, quantity: qtyToAdd };
    if (cartItems && qtyToAdd && productId) {
        let productsWithQty = new Map([
            ...cartItems.map(prodObj => [prodObj?.product?.sku || prodObj?.productId, prodObj?.quantity])
        ]);
        let currentQty = productsWithQty.get(productId) || 0;
        if (currentQty >= QTY_LIMIT) {
            result = { error: true, quantity: 0 };
        } else if (currentQty + qtyToAdd > QTY_LIMIT) {
            result = { error: true, quantity: QTY_LIMIT - currentQty };
        }
    }
    return result;
};

export const redirectSignOutURL = (sessionExpired = false, returnUrl = '') => {
    let redirectURL = '';
    if (sessionExpired && returnUrl) {
        redirectURL = `${config.pagePaths.signout}?sessionExpired=${sessionExpired}&returnUrl=${returnUrl}`;
    } else if (sessionExpired && !returnUrl) {
        redirectURL = `${config.pagePaths.signout}?sessionExpired=${sessionExpired}`;
    } else if (!sessionExpired && returnUrl) {
        redirectURL = `${config.pagePaths.signout}?returnUrl=${returnUrl}`;
    } else {
        redirectURL = config.pagePaths.signout;
    }
    return redirectURL;
};

export const formatNearbyPC = (pc, id = null) => {
    const companyID = parseInt(localStorage.getItem('companyID')) || 1;
    if (id) {
        return `${id}_${nearbyPCFormat(pc)}`;
    } else {
        return companyID === 1 ? `01_${nearbyPCFormat(pc)}` : `02_${nearbyPCFormat(pc)}`;
    }
};
export const nearbyPCFormat = pc => {
    return ('000' + pc).slice(-4);
};

export const selectStartDate = (currentRange, dateClicked, setRangeState) => {
    let currentStartDate = new Date(currentRange[0].startDate).getTime();
    let currentEndDate = new Date(currentRange[0].endDate).getTime();
    if (!isNaN(currentStartDate) && !isNaN(currentEndDate) && !isNaN(dateClicked)) {
        if (dateClicked < currentEndDate) {
            currentRange[0].startDate = new Date(dateClicked);
            setRangeState([...currentRange]);
        } else if (dateClicked > currentEndDate) {
            currentRange[0].startDate = new Date(dateClicked);
            currentRange[0].endDate = '';
            setRangeState([...currentRange]);
        } else if (dateClicked == currentEndDate) {
            currentRange[0].startDate = new Date(dateClicked);
            if (dateClicked == currentStartDate) {
                currentRange[0].endDate = '';
            }
            setRangeState([...currentRange]);
        }
    } else if (!isNaN(currentStartDate) && currentRange[0]?.endDate === '' && !isNaN(dateClicked)) {
        if (dateClicked < currentStartDate) {
            currentRange[0].endDate = currentRange[0].startDate;
            currentRange[0].startDate = new Date(dateClicked);
            setRangeState([...currentRange]);
        } else if (dateClicked > currentStartDate) {
            currentRange[0].endDate = new Date(dateClicked);
            setRangeState([...currentRange]);
        } else if (dateClicked == currentStartDate) {
            currentRange[0].endDate = new Date(dateClicked);

            if (dateClicked == currentEndDate) {
                currentRange[0].startDate = new Date(dateClicked);
                currentRange[0].endDate = '';
            }
            setRangeState([...currentRange]);
        }
    }
};

export const selectEndDate = (currentRange, dateClicked, setRangeState) => {
    let currentStartDate = new Date(currentRange[0].startDate).getTime();
    let currentEndDate = new Date(currentRange[0].endDate).getTime();
    if (!isNaN(currentStartDate) && !isNaN(currentEndDate) && !isNaN(dateClicked)) {
        if (dateClicked > currentStartDate) {
            currentRange[0].endDate = new Date(dateClicked);
            setRangeState([...currentRange]);
        } else if (dateClicked < currentStartDate) {
            currentRange[0].endDate = '';
            currentRange[0].startDate = new Date(dateClicked);
            setRangeState([...currentRange]);
        } else if (dateClicked == currentStartDate) {
            currentRange[0].endDate = new Date(dateClicked);
            setRangeState([...currentRange]);
        }
    } else if (!isNaN(currentStartDate) && currentRange[0]?.endDate === '' && !isNaN(dateClicked)) {
        if (dateClicked < currentStartDate) {
            currentRange[0].endDate = currentRange[0].startDate;
            currentRange[0].startDate = new Date(dateClicked);
            setRangeState([...currentRange]);
        } else if (dateClicked > currentStartDate) {
            currentRange[0].endDate = new Date(dateClicked);
            setRangeState([...currentRange]);
        } else if (dateClicked == currentStartDate) {
            currentRange[0].endDate = new Date(dateClicked);
            setRangeState([...currentRange]);
        }
    }
};

export const triggerCoveoSearchAPI = () => {
    const params = new URLSearchParams(window.location.search);
    const search = params.get('q');
    if (search) {
        customSearchBox?.updateText(search);
        customSearchBox?.submit();
    } else {
        customSearchBox?.submit();
    }
};

export const isInventoryCheckRequired = () => {
    const overridePc = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
    const { disableinventorycheck } = ENV_CONFIG.INVENTORY_CHECK_CONFIGS || {};
    const isInventoryCheckDisabled = disableinventorycheck || false;
    if (isValidString(isInventoryCheckDisabled)) {
        return false;
    } else if (isCheckoutPage()) {
        return false;
    } else if (overridePc?.pc) {
        return false;
    } else {
        return !isTier2Radius();
    }
};

export const isAtpCheckRequired = () => {
    const overridePc = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
    const { rollbackToCi } = ENV_CONFIG.INVENTORY_CHECK_CONFIGS || {};
    const isAtpDisabled = rollbackToCi || false;

    if (isValidString(isAtpDisabled) || isValidString(isTier2Radius())) {
        return isInventoryCheckRequired()
            ? JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ATP_PC_LIST))
            : [];
    } else if (overridePc?.pc) {
        return [];
    } else {
        return JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ATP_PC_LIST));
    }
};

export const isNearByPcsRequired = showUnavailableItems => {
    const overridePc = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
    const startDate = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE) || '';
    const { rollbackToCi } = ENV_CONFIG.INVENTORY_CHECK_CONFIGS || {};
    const isAtpDisabled = rollbackToCi || false;
    if (isValidString(isAtpDisabled) || isValidString(isTier2Radius())) {
        return false;
    } else if (overridePc?.pc) {
        return true;
    } else {
        if (!isValidString(startDate)) {
            return true;
        } else if (window.location.pathname.includes('/search')) {
            return !showUnavailableItems;
        } else if (ENV_CONFIG.PAGE_TEMPLATE_STRING == CATEGORY_PAGE_TEMPLATE_STRING) {
            return false;
        } else {
            return true;
        }
    }
};
export const isTier2Radius = () => {
    const isLocationsTier2Radius = isValidString(
        sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.IS_LOCATIONS_TIER_2_RADIUS)
    );
    const isSourcesTier2Radius = isValidString(
        sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.IS_SOURCES_TIER_2_RADIUS)
    );

    if (
        isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE)) &&
        isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE)) &&
        isCheckoutPage()
    ) {
        return isSourcesTier2Radius;
    }
    return isLocationsTier2Radius;
};

/* Set and remove the tier2radius flag according to the distance */
export const storeTier2Radius = distance => {
    const { tier1SearchRadius } = getSearchRadius() || {};
    if (distance == tier1SearchRadius) {
        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.IS_LOCATIONS_TIER_2_RADIUS);
    } else {
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.IS_LOCATIONS_TIER_2_RADIUS, true);
    }
};
/* Format the locations data to object, to make the iteration easy for ProductTile store modal */
export const formatStoreDataToObject = (stores = []) => {
    const allPcDetails = {};
    for (let i in stores) {
        allPcDetails[formatNearbyPC(stores[i].pc)] = stores[i];
    }
    return allPcDetails;
};

export const setFourHourRentals = (rates, productId) => {
    let fourHourRentals = JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.FOURHOURRENTALS) || '{}');

    const { daily, minimum } = rates || {};

    if (daily != minimum) {
        fourHourRentals[productId] = true;
    } else {
        fourHourRentals[productId] = false;
    }
    localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.FOURHOURRENTALS, JSON.stringify(fourHourRentals));
};

export const getHolidayList = customCompanyId => {
    let holidayList;
    let holidayListUS = document.querySelector('#holidays-us')?.dataset?.holidaylist || '';
    let holidayListCA = document.querySelector('#holidays-ca')?.dataset?.holidaylist || '';

    if (customCompanyId) {
        holidayList = customCompanyId == 2 ? holidayListCA : holidayListUS;
    } else {
        holidayList = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) == 2 ? holidayListCA : holidayListUS;
    }
    let dates = [];
    if (holidayList) {
        const dateString = holidayList?.split(',');
        dates = dateString?.map(dateString => moment(dateString).toDate());
        return dates;
    } else {
        return dates;
    }
};

export const isDateDisabled = customCompanyId => {
    const isSundayEnabled = document.querySelector('#holidayConfigs')?.dataset?.enablesundayrental || 'false';
    const isSaturdayEnabled = document.querySelector('#holidayConfigs')?.dataset?.enablesaturdayrental || 'false';
    const holidays = getHolidayList(customCompanyId);
    return date => {
        const isHoliday = holidays.some(holiday => new Date(holiday).toDateString() === date.toDateString());
        if (isHoliday) {
            return true;
        }
        return (
            (date?.getDay() === 0 && !isValidString(isSundayEnabled)) ||
            (date?.getDay() === 6 && !isValidString(isSaturdayEnabled))
        );
    };
};

export const checkIsInstorePickup = () => {
    const userType = useCheckUser();
    if (checkIsEditQuoteFlow()) {
        return JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP) || false);
    } else {
        const isInstorePickupFromStorage = JSON.parse(
            localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP) || true
        );

        if (userType === USER_TYPE.CREDIT) {
            if (sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.IS_CREDIT_FULFILLMENT_SET)) {
                return isInstorePickupFromStorage;
            } else {
                localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP, false);
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.IS_CREDIT_FULFILLMENT_SET, true);
                return false;
            }
        }
        return isInstorePickupFromStorage;
    }
};

export const isFourHourRental = cart => {
    let items = cart?.items;
    let fourHourRentals = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.FOURHOURRENTALS)
        ? JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.FOURHOURRENTALS))
        : {};
    if (items?.length > 0) {
        return items?.find(item => {
            const { sku = '' } = item?.product;
            if (fourHourRentals[sku]) {
                return true;
            }
        });
    }
    return false;
};

export const splitArrayByIndex = arr => {
    const { abovefoldrateslimit = 9 } = ENV_CONFIG.RATE_CONFIGS || {};
    const splitIndex = parseInt(abovefoldrateslimit);
    const newArr = cloneDeep(arr);
    var firstHalf = newArr.slice(0, splitIndex);
    var secondHalf = newArr.slice(splitIndex);

    if (firstHalf.length > 0 && secondHalf.length > 0) {
        return [firstHalf, secondHalf];
    } else if (firstHalf.length > 0) {
        return [firstHalf];
    }

    return [];
};

export const mobileResponsive = (pcAvailability = true) => {
    return {
        mobile: 2,
        smalltablet: 3,
        tablet: 4,
        smalldesktop: pcAvailability ? 3 : 2,
        desktop: pcAvailability ? 4 : 2
    };
};

export const recommendationMobileResponsive = () => {
    return {
        mobile: 2,
        smalltablet: 3,
        tablet: 4,
        smalldesktop: 6,
        desktop: 6
    };
};

export const isPunchoutPage = () => {
    return window.location.href.includes('/industries/') || window.location.pathname == punchoutPath + '/';
};

export const getAuthorHashedPath = () => {
    const hashed = window.location.hash;
    const pathWithoutHash = hashed.slice(1);
    const lastIndex = pathWithoutHash.lastIndexOf('/');
    return pathWithoutHash.substring(0, lastIndex);
};
// Determine the base path based on the value of isDevelopment
export const isAuthorMode = () => window.location.href.includes('author');
const computePunchoutBasePath = punchoutPath => {
    const path = window.location.pathname.replace(/\/$/, '');
    try {
        if (!isAuthorMode()) {
            return isDevelopment ? '/content/sunbeltrentals/us/en_US' + punchoutPath : punchoutPath;
        } else {
            return path; //ui for authored urls where rest of the route is hashed
        }
    } catch (e) {
        return punchoutPath;
    }
};
export const basePathForPunchout = computePunchoutBasePath(punchoutPath);

export const redirectToCheckoutOrQuotePage = (quoteId = '') => {
    let url = window.location.origin + config.pagePaths.checkoutPage;
    /** Need to add the condition when Create Quote or Edit Quote will be implemented */
    if (authorityType === AUTHORITY_TYPE.P2P) {
        url = window.location.origin + basePathForPunchout;
        if (quoteId) {
            url += config.pagePaths.p2pEditQuote + quoteId;
        } else {
            url += config.pagePaths.p2pCreateQuote;
        }
    }
    console.log(url, 'URL');
    window.location.href = url;
};
export const checkIsEditQuoteFlow = () => {
    // it should have a boolean value
    const showEditQuoteBanner = JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.SHOWEDITQUOTEBANNER));

    if (authorityType === AUTHORITY_TYPE.P2P) {
        return showEditQuoteBanner;
    }

    return false;
};

export const extractCatAndClass = sku => {
    const catId = ('000' + sku.slice(0, 3)).slice(-3);
    const classId = ('0000' + sku.slice(3, 7)).slice(-4);

    return {
        catId,
        classId
    };
};

export const formatCatClass = id => `${id?.slice(0, 3)}-${id?.slice(3)}`;

/**
 * Formats a date string based on the specified format type.
 *
 * @param {string} inputDateString - The input date string to be formatted (e.g., "20230103").
 * @param {string} formatType - The format type to determine how the date should be formatted.
 *   - 'YYYY-MM-DD': Format as "2023-01-03"
 *   - 'MMM D, YYYY': Format as "Jan 3, 2023".
 * @returns {string} - The formatted date string.
 */
export const formatDateDisplay = (inputDateString, formatType) => {
    //inputDateString = "20230103"
    //formatType dateTime/ shortString
    if (!inputDateString) return '-';

    const momentObj = moment(inputDateString, 'YYYYMMDD');
    return momentObj.format(formatType);
};
export const isSBREmployee = recordType => {
    if (recordType && String(recordType).toUpperCase() === QUOTE_DETAIL_RECORD_TYPE_PUNCHOUT) {
        return false;
    }
    return true;
};
/**
 * Formats a string representation of a numeric phone number in the US format.
 * @param {string} number - The string representation of the numeric phone number to be formatted.
 * @returns {string} The formatted phone number.
 */
export const formatPhoneNumberToUSA = number => {
    // Convert the input to a string
    const numberString = String(number);

    // Validate that the input is a string and consists only of numeric characters
    if (!/^\d+$/.test(numberString)) {
        return numberString;
    }
    const formattedNumber = numberString.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    return formattedNumber;
};

export const isAccountNotActive = status => {
    const statusLowerCase = status?.toLowerCase();
    return statusLowerCase !== 'a';
};

export const sentenceCase = str => {
    if (isValidString(str)) {
        return String(str)
            .toLowerCase()
            .split(' ')
            .map(item => {
                return item[0]?.toUpperCase() + item?.slice(1);
            })
            .join(' ');
    }
};

export const isPasswordContainsEmail = (email, password) => {
    const partLength = 4;
    try {
        let emailParts = email.split(/[.\-,_#@]/);
        emailParts = emailParts.filter(part => part.length >= partLength);
        for (var i = 0; i < emailParts.length; i++) {
            let isMatch = password.includes(emailParts[i]);
            if (isMatch) {
                return true;
            }
        }
    } catch (error) {
        logError(error, false, 'isPasswordContainsEmail', {});
    }
    return false;
};
export const formatDate = inputDateString => {
    if (!inputDateString) {
        return undefined;
    }
    return moment(inputDateString).format('MM/DD/YYYY');
};

export const getUniqueCategories = categories => {
    let uniqueCategories = new Set();
    categories?.forEach(category => {
        category?.split('|')?.forEach(cat => {
            const cleanedCat = cat?.replace(/^"|"$/g, '').trim();
            uniqueCategories?.add(cleanedCat);
        });
    });
    return Array?.from(uniqueCategories);
};

export const storeCheckoutData = key => {
    try {
        const checkoutDetails = JSON.parse(
            sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.CHECKOUTUSERDETAILS) || '{}'
        );
        if (isObjectWithKeys(checkoutDetails)) {
            return checkoutDetails[key];
        }
        return null;
    } catch (error) {
        logError(error, false, 'storeCheckoutData', {});
        return null;
    }
};

export const getNoOfItemsOnCurrentPage = pageInfo => {
    const { items_count, current_page, page_size } = pageInfo;
    return items_count ? Math.min(Math.abs((current_page - 1) * page_size - items_count), pageInfo.page_size) : 0;
};

export const getSearchRadius = () => {
    const isSourcesTier2Radius = isValidString(
        sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.IS_SOURCES_TIER_2_RADIUS)
    );
    const { tieroneinvradius, tiertwoinvradius } = ENV_CONFIG.INVENTORY_CHECK_CONFIGS || {};
    let tier1SearchRadius;
    if (isValidString(isSourcesTier2Radius)) {
        tier1SearchRadius = EXPANDED_TIER_ONE_SEARCH_RADIUS;
    } else {
        tier1SearchRadius = tieroneinvradius || 100;
    }
    return {
        tier1SearchRadius,
        tier2SearchRadius: tiertwoinvradius || 500
    };
};

export const isGreenLeafEnabled = () => {
    const { enablegreenleaffeature } = ENV_CONFIG.GREEN_LEAF_CONFIGS || {};
    return isValidString(enablegreenleaffeature);
};

export const showGreenLeafTag = greenLeaf => {
    return isGreenLeafEnabled() && greenLeaf == 1;
};

const getMarketingChannelConfig = () => {
    try {
        const configElement = document.getElementById('marketingchannel');
        if (!configElement) {
            console.warn('Marketing channel config element not found.');
            return {};
        }

        const configData = configElement.dataset?.channeldetails;
        if (!configData) {
            console.warn('Marketing channel config data is missing.');
            return {};
        }

        return JSON.parse(configData);
    } catch (error) {
        console.error('Error parsing marketing channel config:', error);
        return {};
    }
};

export function getSbmChannelPhoneNumbers() {
    try {
        const MARKETING_CHANNEL_CONFIG = getMarketingChannelConfig();
        return {
            DEFAULT: MARKETING_CHANNEL_CONFIG?.defaultPhoneNumber || '800-667-9328',
            ...Object.fromEntries(
                (MARKETING_CHANNEL_CONFIG?.channelDetails || []).map(({ channelName, phoneNumber }) => [
                    channelName.toLowerCase(),
                    phoneNumber
                ])
            )
        };
    } catch (error) {
        console.error('Error initializing SBM channel phone numbers:', error);
        return { DEFAULT: '800-667-9328' };
    }
}


export const convertPhoneNumberAsPerSbmChannel = phoneNumber => {
    try {
        if (!phoneNumber) return phoneNumber;
        const SBM_CHANNEL_MAPPING = getSbmChannelPhoneNumbers();
        const normalizedPhoneNumber = String(phoneNumber).replace(/-/g, '');
        const normalizedDefaultNumber = SBM_CHANNEL_MAPPING?.DEFAULT?.replace(/-/g, '');
        if (normalizedPhoneNumber === normalizedDefaultNumber) {
            const sbmChannelFromStorage =
                sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.SBMCH)?.toLowerCase() ||
                cookieValue(STORAGE_CONFIG.COOKIES.SBMCH)?.toLowerCase();
            const selectedNumber =
                sbmChannelFromStorage &&
                sbmChannelFromStorage !== 'unassigned' &&
                SBM_CHANNEL_MAPPING?.[sbmChannelFromStorage]
                    ? SBM_CHANNEL_MAPPING?.[sbmChannelFromStorage]
                    : SBM_CHANNEL_MAPPING?.ALL_OTHERS || '888-886-8199';
            return phoneNumber.includes('-') ? selectedNumber : selectedNumber.replace(/-/g, '');
        }
        return phoneNumber;
    } catch (error) {
        logError(error, false, 'convertPhoneNumberAsPerSbmChannel', [phoneNumber]);
    }
};

export const formatPhoneNumberMessage = (message, number) => {
    if (!message) return ''; // Handle null/undefined/empty messages

    const formattedNumber = formatPhoneNumberToUSA(number); // Ensure it's hyphen-separated
    return message.replace(/#/g, `<a href="tel:${number}">${formattedNumber}</a>`);
};
