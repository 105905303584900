import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { func, object } from 'prop-types';
import { useUserContext } from '../../aem-core-components/context/UserContext';
import { useFilterState } from '../../components/cap';
import { useCheckAuthorityType, useCheckUser } from '../../hooks/useCheckUser';
import { useDidMount } from '../../hooks/useDidMount';
import { usePageType } from '../../hooks/usePageType';
import { useCookieValue } from '../../aem-core-components/utils/hooks';
import useFavourites from '../../hooks/useFavourites';
import { getMktoCustomerData, mktoLogin } from '../../../api/mktoLeadCreation';
import { updateContextBasedOnCompanyId } from '../../components/search/Engine';
import { getlatLongByAddress } from '../../components/global/modules/location-autocomplete/api/getLocations';
import { getOverridePC } from '../../components/global/api/CommonResponseHandler';
import {
    checkIsInstorePickup,
    getProjectsFromCookie,
    getSearchRadius,
    getURLParams,
    isAccountBlocked,
    isCCPage,
    triggerCoveoSearchAPI
} from '../../components/global/utils/commonUtils';
import { cartInitialState, cartAction } from './index';
import { handleBranchSelectorCall } from '../../components/global/utils/computeAddressUtil';
import { VARIABLE_CONFIG } from '../../constants/analyticsConstants/Variables';
import config from '../../components/App/config';
import { isValidString, logError } from '../../components/global/utils/logger';
import { productListingController } from '../../components/search/controllers/plplistcontroller';
import isObjectWithKeys from '../../aem-core-components/utils/isObjectWithKeys';
import { STORAGE_CONFIG } from '../../constants/storageConfig';
import { ENV_CONFIG } from '../../constants/envConfig';
import { USER_TYPE } from '../../constants/userDetailsConstants';
import { CATEGORY_PAGE_TEMPLATE_STRING } from '../../constants/screenConstants';
import { FREQRENTALS_SIZE } from '../../components/global/modules/freqRentals/constants';
import {
    RESET_LOCATION_DATA,
    SET_ATP_PC_LIST,
    SET_CART_ITEMS,
    SET_CASH_PROFILE_UPDATED,
    SET_CID_PC_LIST,
    SET_CREDIT_NEWADDR_FLAG,
    SET_ESTIMATES_IN_CART,
    SET_IS_COMBINED_TABS_LOADING,
    SET_LOCATION_DATA,
    SET_LOCATION_LOADING,
    SET_RATES_LOADING_FLAG,
    SET_RECOMPUTE_ITEM_AVAILABILITY,
    SET_SEARCH_RADIUS_FOR_LOCATION_CALL,
    SET_SHOW_DELINQUENT_ACCOUNT_MODAL,
    SET_VIEW_CART_FIELDS,
    UPDATE_ACCOUNT,
    UPDATE_PROJECT
} from '../../aem-core-components/actions/constants';
import {
    RESET_SELECTED_STORE_DETAILS,
    SET_END_DATE,
    SET_IS_JOBSITE_RECOMMENDATION_LOADING,
    SET_RECOMMENDED_JOBSITES,
    SET_SELECTED_STORE_DETAILS,
    SET_START_DATE
} from '../../components/cap/constants';
import { VIEW_CART } from '../../constants/cartConstants';
import { AUTHORITY_TYPE } from '../../components/global/constants';

export const CartContext = createContext();

export const getCountryLabel = companyID => {
    if (companyID == 2) {
        return 'CA';
    } else {
        return 'USA';
    }
};

export const computeAddressLine1 = addressObj => {
    let location =
        `${addressObj?.line1 ? addressObj?.line1 + ', ' : ''}` +
        `${addressObj?.city ? addressObj?.city + ', ' : ''}` +
        `${addressObj?.state ? addressObj?.state : ''}` +
        `, ${getCountryLabel(addressObj?.companyId)}`;
    return location;
};

export const CartProvider = props => {
    const [
        { userProfile, userType, isUserProfileLoading, hasAccountChanged, isProfileLoaded },
        { getFreqRentals, isValidSession, dispatch: userDispatch, getAccountProjectsDotCom }
    ] = useUserContext();
    const [
        { viewCart, projectDetails, isJobsiteRecommendationLoading, lastUsedJobsite },
        filterDispatch,
        { setSettingsCookieFunc, getJobSiteRecommendations, getJobsiteFavorites }
    ] = useFilterState();
    const { getFavourites } = useFavourites();
    const userTypeVal = useCheckUser();
    const didMount = useDidMount();
    const pageType = usePageType();

    const factory = props.reducerFactory || cartAction;
    const state = props.initialState || cartInitialState;

    const [, setCartCookie] = useCookieValue('cif.cart');
    const [magentoTokenCookie, setMagentoTokenCookie] = useCookieValue('cif.magentoToken');
    const params = getURLParams();
    const [isAuthCompanyIdDifferent, setIsAuthCompanyIdDifferent] = useState(false);
    const authorityType = useCheckAuthorityType();
    const [userState, dispatch] = useReducer(factory(setCartCookie, setSettingsCookieFunc), state);
    const startDate = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE) || '';
    const endDate = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE) || '';

    useEffect(() => {
        const viewCartData = JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART)) || {};
        const storeDetails = getStoreDetails() || {};
        const locationPCObj = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ)
            ? JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ))
            : {};
        /* logic for setting viewCart from localStorage on page load if PC is available.
           Not considered location as for credit user only PC can be present.
           This will reduce locations PC call for credit user */
        if (isIdenticalCompanyID() && viewCartData?.pc && !parseInt(viewCart?.pc) && isValidSession()) {
            const NewAddress =
                JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS)) || false;
            const isCreditProjectAddress = userTypeVal == USER_TYPE.CREDIT && !NewAddress;
            filterDispatch({
                type: SET_LOCATION_DATA,
                pc: storeDetails?.pc || viewCartData?.pc,
                location: isCreditProjectAddress ? '' : viewCartData?.location,
                jobSiteCity: isCreditProjectAddress ? '' : viewCartData?.jobSiteCity,
                jobSiteState: isCreditProjectAddress ? '' : viewCartData?.jobSiteState,
                jobSiteZip: isCreditProjectAddress ? '' : viewCartData?.jobSiteZip,
                lat: isCreditProjectAddress ? '' : viewCartData?.lat,
                long: isCreditProjectAddress ? '' : viewCartData?.long,
                jobSiteAddr2: isCreditProjectAddress ? '' : viewCartData?.jobSiteAddr2,
                showCreateProject: viewCartData?.showCreateProject,
                pcLat: storeDetails?.latitude || viewCartData?.pcLat,
                pcLong: storeDetails?.longitude || viewCartData?.pcLong
            });
            filterDispatch({
                type: SET_SELECTED_STORE_DETAILS,
                selectedStoreDetails: storeDetails,
                localLat: locationPCObj?.localLat || viewCart?.lat,
                localLong: locationPCObj?.localLong || viewCart?.long,
                distance: locationPCObj?.distance,
                lat: locationPCObj?.lat,
                long: locationPCObj?.long,
                pc: locationPCObj?.pc,
                specialtyTypes: locationPCObj?.specialtyTypes
            });
            dispatch({ type: SET_CREDIT_NEWADDR_FLAG, isCreditNewAddress: NewAddress });
            if (!isCreditProjectAddress) {
                // this is for all user type, if the guest user has location
                userDispatch({ type: 'setIsUserProfileLoading', value: false });
            }
        } else {
            if (userTypeVal === USER_TYPE.CREDIT) {
                dispatch({ type: SET_CREDIT_NEWADDR_FLAG, isCreditNewAddress: false });
                filterDispatch({
                    type: RESET_LOCATION_DATA
                });
            }
            setIsAuthCompanyIdDifferent(true);
            resetPcs();
        }
    }, []);

    const getStoreDetails = () => {
        const selectedStoreDetailsData = JSON.parse(
            localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.SELECTEDSTOREDETAILS)
        );
        if (userTypeVal === USER_TYPE.CREDIT && !sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.IS_FIRST_CART_LOAD)) {
            const firstLocationStore = JSON.parse(
                localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.FIRST_LOCATION_STORE)
            );
            return firstLocationStore?.pc ? firstLocationStore : selectedStoreDetailsData
        }
        return selectedStoreDetailsData;
    }

    useEffect(() => {
        if (startDate === '' && authorityType === AUTHORITY_TYPE.P2P) {
            filterDispatch({ type: SET_START_DATE, startDate });
            filterDispatch({ type: SET_END_DATE, endDate });
        }
    }, [startDate]);

    useEffect(() => {
        const NewAddress = JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS)) || false;
        /* logic for resetting viewCart only if project changes for credit user and location in viewCart is present.
           Not considered PC for resetting as useEffects having PC as dependency will trigger twice for credit user.
           (Reduction of rates API call) */
        if (userType == 0) {
            // NewAddress was true, so to reset viewCart after creating project showCreateProject is introduced
            if ((!NewAddress && viewCart?.location) || viewCart?.showCreateProject) {
                filterDispatch({
                    type: RESET_LOCATION_DATA
                });
            }
        }
    }, [projectDetails?.selectedProjectJobId]);

    useEffect(() => {
        const setUser = async () => {
            if (userType == 0 || userType == 1) {
                const projectInfoCookies = getProjectsFromCookie();
                if (
                    authorityType !== AUTHORITY_TYPE.P2P &&
                    !JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.MKTOLOGGEDIN))
                ) {
                    const mktoLoginPayload = {
                        email: userProfile?.email || '',
                        oktaId: userProfile?.oktaUserId || '',
                        firstName: userProfile?.firstName || '',
                        lastName: userProfile?.lastName || '',
                        phone: userProfile?.phone || '',
                        address: userProfile?.address1 || '',
                        city: userProfile?.city || '',
                        state: userProfile?.state || '',
                        country: localStorage.getItem('companyID') == 2 ? 'CA' : 'US',
                        zipCode: userProfile?.zip || '',
                        unsubscribed: localStorage.getItem('companyID') == 2 ? true : false
                    };
                    try {
                        const { data: customerData, error: customerDataError } = await getMktoCustomerData(
                            userProfile?.email
                        );
                        let isUserPresubscribed = false;
                        let isMktoLoggedIn = false;
                        if (customerData?.data?.success) {
                            if (customerData?.data?.result?.length > 0) {
                                isUserPresubscribed = !isValidString(customerData?.data?.result[0].unsubscribed);
                                isMktoLoggedIn = true;
                            } else {
                                isUserPresubscribed = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) == 1;
                                const { data, error } = await mktoLogin(mktoLoginPayload);
                                if (error) {
                                    logError(error);
                                } else if (data?.data?.success) {
                                    isMktoLoggedIn = true;
                                }
                            }
                            sessionStorage.setItem(
                                STORAGE_CONFIG.SESSION_STORAGE.ISUSERPRESUBSCRIBED,
                                isUserPresubscribed
                            );
                            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.MKTOLOGGEDIN, isMktoLoggedIn);
                        } else if (customerDataError) {
                            logError(customerDataError);
                            /* to avoid repeat call even if error comes */
                            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.MKTOLOGGEDIN, true);
                        }
                    } catch (err) {
                        logError(err);
                        /* to avoid repeat call even if error comes */
                        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.MKTOLOGGEDIN, true);
                    }
                }

                dispatch({ type: 'beginLoading' });
                if (userProfile?.Type == 0) {
                    localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP, checkIsInstorePickup());
                    const companyId =
                        projectInfoCookies?.CurrentWynneAccountCID || userProfile?.accounts[0]?.companyId || 1;
                    localStorage.setItem('companyID', companyId);
                    localStorage.setItem('guid', userProfile?.userGuid);
                    filterDispatch({
                        type: SET_VIEW_CART_FIELDS,
                        key: VIEW_CART.IN_STORE,
                        value: checkIsInstorePickup()
                    });
                    const corpLinkStatusFromAPIResponse = userProfile?.accounts?.find(
                        account =>
                            account?.id ==
                            (projectInfoCookies?.CurrentWynneAccount
                                ? parseInt(projectInfoCookies?.CurrentWynneAccount)
                                : userProfile?.accounts[0]?.account)
                    )?.isCorpLinkAccount;

                    /** Avoiding default selection of account on first load for P2P,
                      making default selection only when there is a single account from API for P2P */
                    if (
                        (authorityType === AUTHORITY_TYPE.P2P &&
                            (projectInfoCookies?.CurrentWynneAccountName || userProfile?.accounts?.length === 1)) ||
                        authorityType === AUTHORITY_TYPE.DOTCOM
                    ) {
                        dispatch({
                            type: UPDATE_ACCOUNT,
                            accountName: projectInfoCookies?.CurrentWynneAccountName
                                ? projectInfoCookies?.CurrentWynneAccountName
                                : userProfile?.accounts[0]?.name,
                            accountNumber: projectInfoCookies?.CurrentWynneAccount
                                ? parseInt(projectInfoCookies?.CurrentWynneAccount)
                                : userProfile?.accounts[0]?.account,
                            accountStatus: projectInfoCookies?.CurrentWynneAccountStatus
                                ? projectInfoCookies?.CurrentWynneAccountStatus
                                : userProfile?.accounts[0]?.accountStatus,
                            isCorpLinkAccount: corpLinkStatusFromAPIResponse
                        });
                    }
                    const NewAddress = localStorage.getItem('isCreditNewAddress')
                        ? JSON.parse(localStorage.getItem('isCreditNewAddress'))
                        : false;
                    if (NewAddress) {
                        filterDispatch({ type: SET_VIEW_CART_FIELDS, key: VIEW_CART.SHOW_CREATE_PROJECT, value: true });
                    } else {
                        filterDispatch({
                            type: SET_VIEW_CART_FIELDS,
                            key: VIEW_CART.SHOW_CREATE_PROJECT,
                            value: false
                        });
                    }
                    dispatch({ type: 'endLoading' });
                } else if (userProfile?.Type == 1) {
                    localStorage.setItem('companyID', userProfile?.companyId);
                    filterDispatch({
                        type: SET_VIEW_CART_FIELDS,
                        key: VIEW_CART.IN_STORE,
                        value: checkIsInstorePickup()
                    });
                    localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP, checkIsInstorePickup());

                    setCartDataFromProfile();
                    dispatch({ type: 'endLoading' });
                } else {
                    dispatch({ type: 'endLoading' });
                    userDispatch({ type: 'setIsUserProfileLoading', value: false });
                }
                localStorage.removeItem('projectDetails');
                userDispatch({ type: 'setIsCheckoutLoading', value: false });
                localStorage.setItem('isCheckoutLoading', false);
            } else if (userType == 2) {
                // for guest user
                filterDispatch({ type: SET_VIEW_CART_FIELDS, key: VIEW_CART.IN_STORE, value: checkIsInstorePickup() });
                localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP, checkIsInstorePickup());
            }
        };
        setUser();
    }, [userType]);

    const setCartDataFromProfile = async () => {
        var addressObj = localStorage.getItem('viewCart') && JSON.parse(localStorage.getItem('viewCart'));
        let cashLat = '';
        let cashLong = '';
        let lastUsedAddress = {};

        if (userProfile?.lastreservationaddressline1) {
            lastUsedAddress = {
                line1: userProfile?.lastreservationaddressline1 || '',
                line2: '',
                city: userProfile?.lastreservationaddresscity || '',
                state: userProfile?.lastreservationaddressstate || '',
                zip: userProfile?.lastreservationaddresspostal_code || '',
                lat: '',
                long: '',
                companyId: userProfile?.lastreservationaddresscountry == 'CA' ? 2 : 1
            };
        } else {
            lastUsedAddress = {
                line1: userProfile?.address1,
                ...userProfile
            };
        }
        /**
         * Checking isAuthCompanyIdDifferent bcz if is false (same companyId) then address will be
         * set same (address before login) in viewCart otherwise need to update with the address
         * returned by login API.
         */

        // this block will get lat and long of given address
        if (!addressObj?.lat || isAuthCompanyIdDifferent) {
            if (
                !lastUsedAddress?.addressLatitude ||
                !lastUsedAddress?.addressLongitude ||
                (lastUsedAddress?.addressLongitude === '0' && lastUsedAddress?.addressLatitude === '0')
            ) {
                const companyID = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) || '1';
                const response = await getlatLongByAddress(companyID, computeAddressLine1(lastUsedAddress));
                if (response?.data?.results?.length > 0) {
                    cashLat = response?.data?.results[0]?.geometry?.location.lat;
                    cashLong = response?.data?.results[0]?.geometry?.location.lng;
                }
            } else {
                cashLat = lastUsedAddress?.addressLatitude;
                cashLong = lastUsedAddress?.addressLongitude;
            }
        }

        let latForApi = '';
        let longForApi = '';
        let pcVal = 0;
        let pcLat = '';
        let pcLong = '';
        // this block will get pc and it's lat and long
        if (
            (!addressObj?.pc || isAuthCompanyIdDifferent) &&
            window.location.pathname.includes(config.pagePaths.productPage)
        ) {
            if (isAuthCompanyIdDifferent && cashLat && cashLong) {
                latForApi = cashLat;
                longForApi = cashLong;
            } else if (addressObj?.lat && addressObj?.long) {
                latForApi = addressObj?.lat;
                longForApi = addressObj?.long;
            }
            ({ pcVal, pcLat, pcLong } = await handleBranchSelectorCall(
                latForApi,
                longForApi,
                '',
                '',
                [],
                !viewCart?.isInStorePickup
            ));
            if (!pcVal) {
                dispatch({ type: 'endLoading' });
            }
        }

        // let's update the viewCart
        if (isAuthCompanyIdDifferent) {
            filterDispatch({
                type: SET_LOCATION_DATA,
                location: computeAddressLine1(lastUsedAddress),
                jobSiteCity: lastUsedAddress?.city,
                jobSiteState: lastUsedAddress?.state,
                jobSiteZip: lastUsedAddress?.zip,
                lat: cashLat,
                long: cashLong,
                jobSiteAddr2: lastUsedAddress?.address2 || '',
                pc: pcVal,
                pcLat: pcLat,
                pcLong: pcLong
            });
        } else {
            filterDispatch({
                type: SET_LOCATION_DATA,
                location: addressObj?.location || computeAddressLine1(lastUsedAddress),
                jobSiteCity: addressObj?.jobSiteCity || lastUsedAddress?.city,
                jobSiteState: addressObj?.jobSiteState || lastUsedAddress?.state,
                jobSiteZip: addressObj?.jobSiteZip || lastUsedAddress?.zip,
                lat: addressObj?.lat || cashLat,
                long: addressObj?.long || cashLong,
                jobSiteAddr2: addressObj?.jobSiteAddr2 || lastUsedAddress?.address2 || '',
                pc: addressObj?.pc || pcVal,
                pcLat: addressObj?.pcLat || pcLat,
                pcLong: addressObj?.pcLong || pcLong
            });
        }

        dispatch({
            type: SET_CASH_PROFILE_UPDATED,
            cashProfileLatLongUpdated: true
        });
        // for cash user
        userDispatch({ type: 'setIsUserProfileLoading', value: false });
    };

    const disableLoaders = () => {
        filterDispatch({ type: SET_LOCATION_LOADING, isLocationsLoading: false });
        dispatch({ type: 'endLoading' });
        dispatch({ type: SET_RATES_LOADING_FLAG, isRatesLoading: false });
        userDispatch({ type: 'endProjectsLoading' });
        userDispatch({ type: 'setIsUserProfileLoading', value: false });
        userDispatch({ type: 'setIsOverRidePCLoading', value: false });
    };

    useEffect(() => {
        // this useEffect is used to update estimate in cartContext based on delivery type i.e delivery or instore selection
        const cartId = userState?.cartId;
        const isInStorePickup = viewCart?.isInStorePickup;
        const estimatesResponse = userState?.cart?.estimatesResponse;

        if (cartId && estimatesResponse) {
            const estimate = JSON.parse(
                sessionStorage.getItem(
                    isInStorePickup
                        ? STORAGE_CONFIG.SESSION_STORAGE.ESTIMATES_PICKUP
                        : STORAGE_CONFIG.SESSION_STORAGE.ESTIMATES_DELIVERY
                )
            );
            dispatch({ type: SET_ESTIMATES_IN_CART, estimate });
        }
    }, [viewCart?.isInStorePickup, userState?.cartId]);

    useEffect(() => {
        const showDelinquentModal = async () => {
            try {
                if (authorityType !== AUTHORITY_TYPE.P2P) {
                    dispatch({
                        type: SET_IS_COMBINED_TABS_LOADING,
                        isCombinedTabsLoading: true
                    });
                    Promise.allSettled([
                        setFavourites(userState?.userAccount?.accountNumber),
                        setFrequentRentals(
                            userState?.userAccount?.accountNumber,
                            userState?.userAccount?.isCorpLinkAccount
                        )
                    ])
                        .then(values => {
                            dispatch({
                                type: SET_IS_COMBINED_TABS_LOADING,
                                isCombinedTabsLoading: false
                            });
                        })
                        .catch(error => {
                            dispatch({
                                type: SET_IS_COMBINED_TABS_LOADING,
                                isCombinedTabsLoading: false
                            });
                        });
                }
                if (
                    isAccountBlocked(userState?.userAccount) &&
                    window.location.pathname !== config.pagePaths.checkoutPage &&
                    !isValidString(params?.hideDelinquentModal)
                ) {
                    dispatch({
                        type: SET_SHOW_DELINQUENT_ACCOUNT_MODAL,
                        showDelinquentModal: true
                    });
                }
                if (isValidString(params?.showDelinquentModal)) {
                    dispatch({
                        type: SET_SHOW_DELINQUENT_ACCOUNT_MODAL,
                        showDelinquentModal: true
                    });
                    const url = new URL(window.location.href);
                    url.searchParams.set('showDelinquentModal', false);
                    window.history.replaceState({}, '', url.href);
                }
                if (isValidString(params?.hideDelinquentModal)) {
                    const url = new URL(window.location);
                    url.searchParams.set('hideDelinquentModal', false);
                    window.history.replaceState({}, '', url.href);
                }
            } catch (er) {
                disableLoaders();
                logError(er, false, 'showDelinquentModal');
            }
        };
        if (userState?.userAccount?.accountNumber && isProfileLoaded) {
            showDelinquentModal();
        }
    }, [userState?.userAccount?.accountNumber, isProfileLoaded]);

    useEffect(() => {
        const dedicatedPCapiCall = async () => {
            try {
                userDispatch({ type: 'setIsOverRidePCLoading', value: true });
                let overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC));
                const locationPCObj = JSON.parse(
                    sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ) || '{}'
                );
                if (!overridePC) {
                    const response = await getOverridePC(userState?.userAccount?.accountNumber);
                    overridePC = response?.data?.data?.overridePC?.[0] || {};
                    sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC, JSON.stringify(overridePC));
                    if (authorityType === AUTHORITY_TYPE.P2P) {
                        filterDispatch({ type: SET_LOCATION_LOADING, isLocationsLoading: false });
                    }
                }
                if (overridePC?.pc) {
                    localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP, false);
                    filterDispatch({
                        type: SET_VIEW_CART_FIELDS,
                        key: VIEW_CART.IN_STORE,
                        value: false
                    });
                }
                if (overridePC?.pc) {
                    filterDispatch({
                        type: SET_SELECTED_STORE_DETAILS,
                        selectedStoreDetails: overridePC,
                        localLat: locationPCObj?.localLat,
                        localLong: locationPCObj?.localLong
                    });
                    updateContextBasedOnCompanyId(
                        [],
                        [
                            {
                                key: 'nearbypcs',
                                val: []
                            }
                        ]
                    );
                    if (window.location.pathname.includes('/search')) {
                        triggerCoveoSearchAPI();
                    }
                    if (ENV_CONFIG.PAGE_TEMPLATE_STRING == CATEGORY_PAGE_TEMPLATE_STRING) {
                        productListingController?.refresh();
                    }
                }
                userDispatch({ type: 'setIsOverRidePCLoading', value: false });
            } catch (er) {
                disableLoaders();
                logError(er, false, 'dedicatedPCapiCall', [userState?.userAccount?.accountNumber]);
            }
        };
        const cacheAndGetJobsiteRecommendations = async () => {
            try {
                let formattedFavoritesJobsites = [];
                if (userState?.userAccount?.isValueUpdated && isCCPage()) {
                    window.location.reload();
                } else {
                    if (authorityType === AUTHORITY_TYPE.P2P) {
                        // This block is used to get Favorite jobsite list for P2P
                        formattedFavoritesJobsites = await getJobsiteFavorites(userState?.userAccount?.accountNumber);
                    }
                    const recommendedJobsiteData = sessionStorage.getItem(
                        STORAGE_CONFIG.SESSION_STORAGE.RECOMMENDED_JOBSITE
                    );
                    if (recommendedJobsiteData) {
                        filterDispatch({
                            type: SET_RECOMMENDED_JOBSITES,
                            recommendedJobsites: JSON.parse(recommendedJobsiteData) || []
                        });
                        filterDispatch({ type: SET_IS_JOBSITE_RECOMMENDATION_LOADING, isLoading: false });
                    } else {
                        getJobSiteRecommendations(userState?.userAccount?.accountNumber, formattedFavoritesJobsites);
                    }
                }
            } catch (er) {
                disableLoaders();
                logError(er, false, 'cacheAndGetJobsiteRecommendations');
            }
        };
        if (userState?.userAccount?.accountNumber) {
            dedicatedPCapiCall();
            cacheAndGetJobsiteRecommendations();
        }
    }, [userState?.userAccount?.accountNumber]);

    const isIdenticalCompanyID = () => {
        const projectInfoCookies = getProjectsFromCookie();
        const companyID = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID);
        if (userTypeVal == USER_TYPE.GUEST) {
            return true;
        } else {
            return projectInfoCookies?.CurrentWynneAccountCID == companyID;
        }
    };

    const setFavourites = async accountNum => {
        try {
            if (magentoTokenCookie && userProfile && userProfile?.accounts && accountNum && !isCCPage()) {
                if (window.location.pathname !== config.pagePaths.checkoutPage) {
                    //favorites not needed on checkout page
                    const localWishListData = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCALWISHLISTDATA);
                    if (!isValidString(localWishListData)) {
                        const wishListData = await getFavourites(accountNum);
                        userDispatch({
                            type: 'wishlist',
                            wishListData: {
                                items: wishListData?.items || [],
                                items_count: wishListData?.items_count || 0
                            }
                        });
                        sessionStorage.setItem(
                            STORAGE_CONFIG.SESSION_STORAGE.LOCALWISHLISTDATA,
                            JSON.stringify(wishListData)
                        );
                    } else {
                        const localWishListDataObj = JSON.parse(localWishListData);
                        userDispatch({
                            type: 'wishlist',
                            wishListData: {
                                items: localWishListDataObj?.items || [],
                                items_count: localWishListDataObj?.items_count || 0
                            }
                        });
                    }
                }
            }
        } catch (error) {
            logError(error, false, 'setFavourites', [accountNum]);
            return error;
        }
    };

    const setFrequentRentals = async (accountNum, isCorpLink) => {
        try {
            if (pageType === VARIABLE_CONFIG.PAGE_TYPE.HOME_PAGE) {
                let superCategory = 0;
                let isCorpLinkAccount = isCorpLink
                    ? isCorpLink
                    : userProfile?.accounts?.find(item => item?.account === accountNum)?.isCorpLinkAccount;
                if (isCorpLinkAccount == undefined) return;
                const { data, error } = await getFreqRentals(accountNum, isCorpLinkAccount ? 1 : 0);
                let result = [];
                let remainingSize = FREQRENTALS_SIZE;
                if (superCategory) {
                    for (let index = 0; index < data?.length; index++) {
                        if (result.length >= FREQRENTALS_SIZE) break;
                        else {
                            if (
                                data[index].catClassList?.length > 0 &&
                                data[index]?.catClassList[0]?.superCategory == superCategory
                            ) {
                                let localArray = data[index].catClassList?.splice(0, remainingSize);
                                remainingSize = remainingSize - localArray.length;
                                result.push(...localArray);
                            }
                        }
                    }
                } else {
                    for (let index = 0; index < data?.length; index++) {
                        if (data[index]?.catClassList?.length > 0) {
                            let localArray = data[index].catClassList;
                            result.push(...localArray);
                        }
                    }
                }
                userDispatch({
                    type: 'freqRentals',
                    freqRentalsData: result || []
                });
            }
        } catch (err) {
            logError(err, false);
        }
    };

    const resetPcs = () => {
        filterDispatch({ type: RESET_SELECTED_STORE_DETAILS });
        localStorage.removeItem(STORAGE_CONFIG.LOCAL_STORAGE.FIRST_LOCATION_STORE);
        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.SOURCEPCOBJ);
        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ);
        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.CID_PC_LIST);
        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.BSR_PC_LIST);
        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.AVS_RESPONSE);
        dispatch({ type: SET_CID_PC_LIST, cidPcList: [] });
        dispatch({ type: SET_ATP_PC_LIST, atpPcList: [] });
        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.IS_SOURCES_TIER_2_RADIUS);
        const { tier1SearchRadius } = getSearchRadius() || {};
        filterDispatch({
            type: SET_SEARCH_RADIUS_FOR_LOCATION_CALL,
            key: 'tier1SearchRadius',
            value: tier1SearchRadius
        });
    };

    const getLatLongJobsite = async (lat, long, fullAddress) => {
        try {
            if (lat && long) {
                return { projectLat: lat, projectLong: long };
            } else {
                let projectLat = '';
                let projectLong = '';
                const companyID = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) || '1';
                const response = await getlatLongByAddress(companyID, fullAddress);
                if (response?.data?.results?.length > 0) {
                    projectLat = response?.data?.results[0]?.geometry?.location.lat;
                    projectLong = response?.data?.results[0]?.geometry?.location.lng;
                }
                return { projectLat, projectLong };
            }
        } catch (er) {
            disableLoaders();
            logError(er, false, 'getLatLongJobsite');
        }
    };

    const recomputeCartOnAccountChange = () => {
        if (hasAccountChanged) {
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.CART_ITEMS);
            dispatch({ type: SET_RECOMPUTE_ITEM_AVAILABILITY, recomputeItemsAvailability: true });
        }
    };

    const setAccountProject = async () => {
        try {
            const { projectData } = await getAccountProjectsDotCom(userState?.userAccount?.accountNumber);
            if (projectData?.length) {
                const projectFullAddress = `${projectData[0]?.address1}, ${projectData[0]?.city}, ${projectData[0]?.state}, ${projectData[0]?.zip}`;
                const { projectLat, projectLong } = await getLatLongJobsite(
                    projectData[0]?.latitude,
                    projectData[0]?.longitude,
                    projectFullAddress
                );
                const NewAddress =
                    JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS)) || false;
                const overridePC = JSON.parse(
                    sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}'
                );
                if (userTypeVal === USER_TYPE.CREDIT && !NewAddress) {
                    filterDispatch({
                        type: UPDATE_PROJECT,
                        locationPC: parseInt(overridePC?.pc) || projectData[0]?.locationPC,
                        projectName: projectData[0]?.jobName || '',
                        projectAddress1: projectData[0]?.address1 || '',
                        projectAddress2: projectData[0]?.address2 || '',
                        selectedProjectJobId: projectData[0]?.jobNumber || '',
                        selectedRMJobId: projectData[0]?.rmJobsiteId,
                        selectedProjectLatititude: projectLat || '',
                        selectedProjectLongitude: projectLong || '',
                        selectedProjectState: projectData[0]?.state || '',
                        selectedProjectCity: projectData[0]?.city || '',
                        selectedProjectZip: projectData[0]?.zip || '',
                        primaryContactName: projectData[0]?.contactName || '',
                        phoneNumber: projectData[0]?.contactPhone || '',
                        accessNotes: projectData[0]?.deliveryInstructions || '',
                        poNumber: projectData[0]?.customerPO || '',
                        CJDLVY: projectData[0]?.CJDLVY || ''
                    });
                }

                localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.CJDLVY, projectData[0]?.CJDLVY);
                if (overridePC?.pc) {
                    filterDispatch({
                        type: SET_VIEW_CART_FIELDS,
                        key: VIEW_CART.PCLAT,
                        value: overridePC?.latitude
                    });
                    filterDispatch({
                        type: SET_VIEW_CART_FIELDS,
                        key: VIEW_CART.PCLONG,
                        value: overridePC?.longitude
                    });
                    const viewCartFromStorage = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART)
                        ? JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART))
                        : viewCart;
                    localStorage.setItem(
                        STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART,
                        JSON.stringify({
                            ...viewCartFromStorage,
                            [VIEW_CART.PC]: overridePC?.pc,
                            [VIEW_CART.PCLAT]: overridePC?.latitude,
                            [VIEW_CART.PCLONG]: overridePC?.longitude
                        })
                    );

                    let browsePCObj =
                        localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE) &&
                        localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE) &&
                        isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY)) > 0
                            ? STORAGE_CONFIG.SESSION_STORAGE.SOURCEPCOBJ
                            : STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ;
                    sessionStorage.setItem(
                        browsePCObj,
                        JSON.stringify({
                            pc: overridePC?.pc,
                            lat: overridePC?.latitude,
                            long: overridePC?.longitude,
                            localLat: projectLat,
                            localLong: projectLong
                        })
                    );
                }
                if (!viewCart?.location) {
                    filterDispatch({
                        type: SET_VIEW_CART_FIELDS,
                        key: VIEW_CART.SHOW_CREATE_PROJECT,
                        value: false
                    });
                }
            } else {
                recomputeCartOnAccountChange();
                disableLoaders();
                // resetPcs();
            }
            userDispatch({ type: 'setIsUserProfileLoading', value: false });
        } catch (er) {
            recomputeCartOnAccountChange();
            disableLoaders();
            // resetPcs();
            logError(er, false, 'setAccountProject');
        }
    };

    const setCookieProject = async () => {
        /** project details initial value is already set from cookies
         *  in initial filter state, so not setting again.
         */
        try {
            if (!projectDetails?.selectedProjectJobId) {
                filterDispatch({
                    type: SET_VIEW_CART_FIELDS,
                    key: VIEW_CART.SHOW_CREATE_PROJECT,
                    value: false
                });
            }
            userDispatch({ type: 'setIsUserProfileLoading', value: false });
        } catch (er) {
            disableLoaders();
            logError(er, false, 'setCookieProject');
        }
    };

    const setLastUsedProject = async () => {
        try {
            const fullAddress = `${lastUsedJobsite?.address1}, ${lastUsedJobsite?.city}, ${lastUsedJobsite?.state}, ${lastUsedJobsite?.zip}`;
            const { projectLat, projectLong } = await getLatLongJobsite(
                lastUsedJobsite?.latitude,
                lastUsedJobsite?.longitude,
                fullAddress
            );
            const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
            filterDispatch({
                type: UPDATE_PROJECT,
                locationPC: overridePC?.pc || lastUsedJobsite?.locationPC,
                projectName: lastUsedJobsite?.jobName || '',
                projectAddress1: lastUsedJobsite?.address1 || '',
                projectAddress2: lastUsedJobsite?.address2 || '',
                selectedProjectJobId: lastUsedJobsite?.jobNumber || '',
                selectedProjectLatititude: projectLat || '',
                selectedProjectLongitude: projectLong || '',
                selectedProjectState: lastUsedJobsite?.state || '',
                selectedProjectCity: lastUsedJobsite?.city || '',
                selectedProjectZip: lastUsedJobsite?.zip || '',
                primaryContactName: lastUsedJobsite?.contactName || '',
                phoneNumber: lastUsedJobsite?.contactPhone || '',
                accessNotes: lastUsedJobsite?.deliveryInstructions || '',
                poNumber: lastUsedJobsite?.customerPO || '',
                CJDLVY: lastUsedJobsite?.CJDLVY || ''
            });
            userDispatch({ type: 'setIsUserProfileLoading', value: false });
        } catch (er) {
            disableLoaders();
            logError(er, false, 'setLastUsedProject');
        }
    };

    window.updateCartQtyfromCC = totalQty => {
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY, totalQty);
        dispatch({
            type: SET_CART_ITEMS,
            total_quantity: totalQty
        });
    };

    useEffect(() => {
        const setProject = () => {
            try {
                if (didMount && userTypeVal === USER_TYPE.CREDIT) {
                    const isNewAddress =
                        JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS)) || false;
                    const isValidAddress = isNewAddress && isIdenticalCompanyID();
                    if (!isValidAddress) {
                        if (projectDetails?.projectName && !hasAccountChanged) {
                            // when user has not changed the account and project is present in cookies -> case of reload
                            setCookieProject();
                        } else if (authorityType === AUTHORITY_TYPE.P2P) {
                            // avoiding default jobsites selection for p2p users
                            userDispatch({ type: 'setIsUserProfileLoading', value: false });
                        } else if (!isJobsiteRecommendationLoading) {
                            // when user has just logged in or has changed the account
                            // and the last used jobsite api is complete then,
                            // check if last used jobsite has data then set it
                            // else set project from account
                            if (isObjectWithKeys(lastUsedJobsite)) {
                                setLastUsedProject();
                            } else {
                                setAccountProject();
                            }
                            // else will wait for the last used jobsite to complete
                            // isJobsiteRecommendationLoading will re-trigger the useEffect once the api is finished
                        }
                    }
                }
            } catch (er) {
                disableLoaders();
                logError(er, false, 'setProject');
            }
        };
        setProject();
    }, [didMount, lastUsedJobsite, isJobsiteRecommendationLoading]);

    const contextValue = [userState, dispatch];

    return <CartContext.Provider value={contextValue}>{props.children}</CartContext.Provider>;
};

CartProvider.propTypes = {
    reducerFactory: func,
    initialState: object
};

export const useCartState = () => useContext(CartContext);
