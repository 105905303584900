import React, { memo, useState } from 'react';
import { useIntl } from 'react-intl';
import useAnalytics from '../../../hooks/analytics/useAnalytics';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import { useCheckUser } from '../../../hooks/useCheckUser';
import Button from '../../global/atoms/button';
import OktaModal from '../../global/modules/oktaModal/OktaModal';
import './capHeaderSignIn.scss';
import config from '../../App/config';

const CapHeaderSignIn = () => {
    const capIntl = useIntl();
    const userType = useCheckUser();
    const [{ handleSignInCTAClickEvent }] = useAnalytics();
    const [isOktaModalOpen, setIsOktaModalOpen] = useState(false);
    const { linktext } = ENV_CONFIG.CAP_HEADER_AEM_RIGHT_CONTENT || {};
    const signInLinkText = linktext || capIntl.formatMessage({ id: 'cap:sign-in' });

    const signInHandler = () => {
        setIsOktaModalOpen(!isOktaModalOpen);
        handleSignInCTAClickEvent();
    };
    const handleCloseOkta = () => {
        setIsOktaModalOpen(!isOktaModalOpen);
    };

    return userType === USER_TYPE.GUEST ? (
        <>
            <Button
                className="button sign-in-btn-cap-home"
                buttonAriaLabel={signInLinkText}
                onClick={signInHandler}
                dataTestid="cap-sign-in">
                <span className="cap-header-aem__person icon icon-person-icon " role="presentation" aria-hidden="true"></span>
                {signInLinkText}
            </Button>
            <OktaModal
                isOpen={isOktaModalOpen}
                handleRequestClose={handleCloseOkta}
                handleCloseOkta={handleCloseOkta}
                urlPath={config.pagePaths.signInIframe}
            />
        </>
    ) : null;
};

export default memo(CapHeaderSignIn);
