import { buildSearchEngine, buildContext, getOrganizationEndpoints } from '@coveo/headless';
import { useCheckAuthorityType } from '../../hooks/useCheckUser';
import { AUTHORITY_TYPE } from '../global/constants';
import { STORAGE_CONFIG } from '../../constants/storageConfig';
const { buildProductListingEngine } = require('@coveo/headless/product-listing');

const coveoConfig =
    document.querySelector('meta[name="generic-variables"]')?.dataset ||
    document.querySelector('div[name="generic-variables"]')?.dataset;
export const defaultEngineOptions = {
    configuration: {
        organizationId: coveoConfig?.orgid || 'sunbeltrentalsnonproduction2l4v2y05t',
        accessToken: coveoConfig?.accesstoken || 'xxc5d4b939-0b2d-4c78-bd1b-8c10a3da9c4b',
        organizationEndpoints: getOrganizationEndpoints(coveoConfig?.orgid || 'sunbeltrentalsnonproduction2l4v2y05t'),
        search: {
            searchHub: coveoConfig?.coveoSearchhub || 'Sunbelt-Commerce'
        }
    }
};
const handlePreprocessRequest = (request = null, clientOrigin = null) => {
    const body = JSON.parse(request.body);
    if (body.facets) {
        body.facets.requests = body.facets.requests.map(req => {
            //since numerical facets doesnt support reordering
            if (req.currentValues?.length > 0 && req.type !== 'numericalRange') {
                if (Array(req.currentValues).findIndex(item => item.state === 'selected') > -1) {
                    //atleast one item is selected
                    req.freezeCurrentValues = true;
                }
            } else {
                req.freezeCurrentValues = false;
            }
            return req;
        });
    }
    request.body = JSON.stringify(body);
    return request;
};
export const defaultPlpEngineOptions = {
    configuration: {
        organizationId: coveoConfig?.orgid || 'sunbeltrentalsnonproduction1nxhhxbub',
        accessToken:  coveoConfig?.plpaccesstoken || 'xxc5d4b939-0b2d-4c78-bd1b-8c10a3da9c4b', //nonPro2 key
        // accessToken: coveoConfig?.accesstoken || 'xxc5d4b939-0b2d-4c78-bd1b-8c10a3da9c4b', // key to run in local setup
        organizationEndpoints: getOrganizationEndpoints(coveoConfig?.orgid || 'sunbeltrentalsnonproduction1nxhhxbub'),
        search: {
            searchHub: coveoConfig?.coveoSearchhub || 'Sunbelt-Commerce'
        },
        analytics: {
            analyticsClientMiddleware: (eventType, payload) => {
                payload.originLevel1 = 'Listing';
                payload.originLevel2 = sessionStorage.getItem('listingReqUrl') || '';
                return payload;
            }
        },
        preprocessRequest: handlePreprocessRequest
    }
};

export const defaultPDPEngineOptions = {
    configuration: {
        organizationId: coveoConfig?.orgid || 'sunbeltrentalsnonproduction2l4v2y05t',
        accessToken: coveoConfig?.altInventoryAccessToken || 'xxc5d4b939-0b2d-4c78-bd1b-8c10a3da9c4b',
        organizationEndpoints: getOrganizationEndpoints(coveoConfig?.orgid || 'sunbeltrentalsnonproduction2l4v2y05t'),
        search: {
            searchHub: coveoConfig?.coveoAltInventorySearchhub || 'PDP-Alt-Inventory-Rec'
        }
    }
};

export const relatedInfoSugesstions = {
    configuration: {
        organizationId: coveoConfig?.orgid || 'sunbeltrentalsnonproduction1nxhhxbub',
        accessToken: coveoConfig?.coveoRelatedinfoaccesstoken || 'xx21202c1b-acad-424d-ad77-277faf80780c',
        organizationEndpoints: getOrganizationEndpoints(coveoConfig?.orgid || 'sunbeltrentalsnonproduction1nxhhxbub'),
        search: {
            searchHub: coveoConfig?.coveoRelatedinfosearchhub || 'RelatedInfoSuggestions'
        }
    }
};

export const productInfoSugesstions = {
    configuration: {
        organizationId: coveoConfig?.orgid || 'sunbeltrentalsnonproduction2l4v2y05t',
        accessToken: coveoConfig?.accesstoken || 'xxc5d4b939-0b2d-4c78-bd1b-8c10a3da9c4b',
        organizationEndpoints: getOrganizationEndpoints(coveoConfig?.orgid || 'sunbeltrentalsnonproduction2l4v2y05t'),
        search: {
            searchHub: 'Sunbelt-Commerce-Search'
        }
    }
};
var rentalsEngine = '';
var rental_engine_options = {
    configuration: {
        ...defaultEngineOptions.configuration,
        analytics: {
            analyticsClientMiddleware: (eventType, payload) => {
                if (payload.actionCause === 'documentOpen') {
                    const matchingResult = rentals_engine.state.search.results[payload.documentPosition - 1];
                    const customUADataMap = {
                        name: 'ec_name',
                        brand: 'ec_brand',
                        category: 'ec_category',
                        // 'contentidvalue': 'ec_skus',
                        price: 'ec_price'
                    };
                    Object.keys(customUADataMap).forEach(uaKey => {
                        const resultKey = customUADataMap[uaKey];
                        const resultValue = matchingResult.raw[resultKey];
                        if (Array.isArray(resultValue) && resultValue.length) {
                            payload.customData[uaKey] = resultValue[resultValue.length - 1];
                        } else {
                            payload.customData[uaKey] = resultValue;
                        }
                    });
                }
                return payload;
            }
        }
    }
};

rentalsEngine = buildSearchEngine(rental_engine_options);
export const relinfos_engine = buildSearchEngine(defaultEngineOptions);
export const similar_rentals_engine = buildSearchEngine(defaultPDPEngineOptions);
export const productListingEngine = buildProductListingEngine(defaultPlpEngineOptions);
export const productsuggestion_engine = buildSearchEngine(productInfoSugesstions);
export const rentals_engine = rentalsEngine;

export const buildContextForAltInventoryEngine = (contextKeyVals = []) => {
    const similarItemsContext = buildContext(similar_rentals_engine);
    for (let i = 0; i < contextKeyVals.length; i++) {
        similarItemsContext.add(contextKeyVals[i]?.key, contextKeyVals[i]?.val);
    }
};
export const buildContextBasedOnCompanyId = (engineArr = []) => {
    const engines = [rentals_engine, relinfos_engine, productListingEngine, similar_rentals_engine, ...engineArr];
    engines.forEach(engine => {
        const context = buildContext(engine);
        const companyID = localStorage.getItem('companyID') || 1;
        if (companyID && companyID == 2) {
            context.add('website', 'en_CA');
        } else {
            context.add('website', 'en_US');
        }
    });
};

export const updateContextBasedOnCompanyId = (engineArr = [], contextKeyVals = [], isAtpDisabled = false) => {
    const authorityType = useCheckAuthorityType();
    const isP2PUser = authorityType === AUTHORITY_TYPE.P2P;
    const isDelivery = !JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP)); 
    const disableAtp = isP2PUser && isDelivery || isAtpDisabled;
    const engines = [rentals_engine, relinfos_engine, productListingEngine, similar_rentals_engine, ...engineArr];
    engines.forEach(engine => {
        const context = buildContext(engine);
        for (let i = 0; i < contextKeyVals.length; i++) {
            if(disableAtp && contextKeyVals[i]?.key === 'nearbypcs'){
                contextKeyVals[i].val = [];
            }
            context.add(contextKeyVals[i]?.key, contextKeyVals[i]?.val);
        }
    });
};
